import {React, useState} from 'react'
import Lake from "../../static/Lake.svg"
import SimpleLake from "../../static/LakeSimple.svg"
import SimpleNature from "../../static/SimpleNature.svg"
import Nature from "../../static/Nature.svg"
import Artificial from "../../static/Artificial.svg"
import SimpleArtificial from "../../static/SimpleArtificial.svg"
import Cutting from "../../static/Cutting.svg"
import SimpleCutting from "../../static/SimpleCutting.svg"

function SolutionSection() {
   const [flagOne , setFlagOne] = useState(false);
   const [flagTwo , setFlagTwo] = useState(false);
   const [flagThree , setFlagThree] = useState(false);
   const [flagFour , setFlagFour] = useState(false);
  return (
    <div className='flex flex-col justify-center items-center align-center w-full mt-40'>
         <p className=' font-bold text-4xl text-black w-full text-center'>WE ARE SOLVING</p>
         <div className=' hidden px-4 sm:px-0 mt-8 sm:flex text-center font-normal text-lg text-[#757575] leading-9 '>
            <p>Our ‘super-wicked’ problems are borderless and overlap multiple themes,<br></br> but at the heart of it is – Nature. </p>
         </div>
         <div className='sm:hidden px-4 sm:px-0 mt-8 flex text-center font-normal text-lg text-[#757575] leading-9 '>
            <p>Our ‘super-wicked’ problems are borderless and overlap multiple themes, but at the heart of it is – Nature. </p>
         </div>
         <div className=' hidden sm:flex mt-20 justify-between align-center items-center space-x-28 '>
            <div className='flex flex-col space-y-3.5 align-center items-center'>
               <img src={flagOne ? Lake : SimpleLake} alt="Lake"  onMouseEnter={() => setFlagOne(true)} onMouseLeave={() => setFlagOne(false)}></img>
               <p className=' font-semibold text-base text-black'>Water Ecosystems</p>
            </div>
            <div className='flex flex-col space-y-3.5 align-center items-center'>
               <img src={flagTwo ? SimpleNature : Nature} alt="Lake"  onMouseEnter={() => setFlagTwo(true)} onMouseLeave={() => setFlagTwo(false)}></img>
               <p className=' font-semibold text-base text-black text-center'>Sustainable<br></br> Food-Systems</p>
            </div>
            <div className='flex flex-col space-y-3.5 align-center items-center'>
               <img src={!flagThree ? Artificial : SimpleArtificial} alt="Lake" onMouseEnter={() => setFlagThree(true)} onMouseLeave={() => setFlagThree(false)}></img>
               <p className=' font-semibold text-base text-black text-center'>Nature-Based and <br></br>Natural Climate Solutions</p>
            </div>
            <div className='flex flex-col space-y-3.5 align-center items-center'>
               <img src={!flagFour ? Cutting : SimpleCutting} alt="Lake" onMouseEnter={() => setFlagFour(true)} onMouseLeave={() => setFlagFour(false)}></img>
               <p className=' font-semibold text-base text-black text-center'>Nature-positive<br></br> Businesses</p>
            </div>
         </div>
         <div className=' hidden sm:flex text-center mx-[180px] mt-[50px] text-base font-normal leading-9 text-[#757575]'>
            <p>
            NatureDots’ product, <strong>AquaNurch® System</strong> is an intelligent systemic based think machine (our AI loves Nature) which is unwrapping the interconnection of complex ecosystems by using the power of technology and science (to simplify and not to complicate). 
Given the complexities of Aquatic ecosystem, NatureDots’ team goes beyond conventional approach of data analytics, water parameters monitoring using IoT and geo spatial analysis. With our system and solutions we are transforming aquaculture based food-systems, ensuring healthy fisheries (food-security and protein security) and re-installing the ecosystem health by assisting in restoration, conservation and protection.
            </p>
         </div>

         <div className='sm:hidden grid grid-cols-2 gap-10 mt-20 mx-5'>
         <div className='flex flex-col space-y-3.5 align-center items-center'>
               <img src={Lake} alt="Lake"/>
               <p className=' font-semibold text-sm text-black'>Water Ecosystems</p>
            </div>
            <div className='flex flex-col space-y-3.5 align-center items-center'>
               <img src={SimpleNature} alt="Lake"/>
               <p className=' font-semibold text-sm text-black text-center'>Sustainable Food-Systems</p>
            </div>
            <div className='flex flex-col space-y-3.5 align-center items-center'>
               <img src={SimpleArtificial} alt="Lake"/>
               <p className=' text-center font-semibold text-sm text-black'>Nature-Based and <br></br>Natural Climate Solutions</p>
            </div>
            <div className='flex flex-col space-y-3.5 align-center items-center'>
               <img src={SimpleCutting} alt="Lake"/>
               <p className=' font-semibold text-sm text-black text-center'>Nature-positive Businesses</p>
            </div>
         </div>

    </div>
  )
}

export default SolutionSection