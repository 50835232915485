import React, { useEffect } from 'react'
import LocationMap from "../../../static/LocationMap.png"

function TeamLocation() {
  useEffect(() => {
    
  }, [])
  return (
    <div className='w-full mt-10 sm:mt-20 items-center flex flex-col'>
            <div className='flex flex-col w-full mt-14 text-center'>
                <p className='font-bold text-2xl px-2 sm:px-0 sm:text-4xl text-black'>We work from everywhere all <br></br>over the Globe</p>
                <p className='hidden sm:block font-normal text-lg text-[#757575] mt-6'>At NatureDots we are <strong>committed to achieving workforce diversity</strong> in terms of gender, LGBTQ, nationality, and <br></br> individuals from minority groups, indigenous groups and differently-abled persons are equally encouraged to apply!</p>
            </div>
            {/* <img 
                className='mt-12 rounded-xl'
                src="https://api.mapbox.com/styles/v1/mapbox/streets-v12/static/geojson(%7B%0A%20%20%22type%22%3A%20%22FeatureCollection%22%2C%0A%20%20%22features%22%3A%20%5B%0A%20%20%20%20%7B%0A%20%20%20%20%20%20%22type%22%3A%20%22Feature%22%2C%0A%20%20%20%20%20%20%22properties%22%3A%20%7B%0A%20%20%22marker-color%22%3A%20%22%23462eff%22%2C%0A%20%20%22marker-size%22%3A%20%22medium%22%2C%0A%20%20%20%22marker-symbol%22%3A%20%22s%22%0A%7D%2C%0A%20%20%20%20%20%20%22geometry%22%3A%20%7B%0A%20%20%20%20%20%20%20%20%22coordinates%22%3A%20%5B%0A%20%20%20%20%20%20%20%20%20%2077.21078208782342%2C%0A%20%20%20%20%20%20%20%20%20%2028.6161989765139%0A%20%20%20%20%20%20%20%20%5D%2C%0A%20%20%20%20%20%20%20%20%22type%22%3A%20%22Point%22%0A%20%20%20%20%20%20%7D%0A%20%20%20%20%7D%2C%0A%20%20%20%20%7B%0A%20%20%20%20%20%20%22type%22%3A%20%22Feature%22%2C%0A%20%20%20%20%20%20%22properties%22%3A%20%7B%0A%20%20%22marker-color%22%3A%20%22%23462eff%22%2C%0A%20%20%22marker-size%22%3A%20%22medium%22%2C%0A%20%20%20%22marker-symbol%22%3A%20%22s%22%0A%7D%2C%0A%20%20%20%20%20%20%22geometry%22%3A%20%7B%0A%20%20%20%20%20%20%20%20%22coordinates%22%3A%20%5B%0A%20%20%20%20%20%20%20%20%20%2075.86825875000022%2C%0A%20%20%20%20%20%20%20%20%20%2022.720773335079357%0A%20%20%20%20%20%20%20%20%5D%2C%0A%20%20%20%20%20%20%20%20%22type%22%3A%20%22Point%22%0A%20%20%20%20%20%20%7D%0A%20%20%20%20%7D%0A%20%20%5D%0A%7D)/77.21078208782342,28.6161989765139,13,4/900x500?access_token=pk.eyJ1Ijoiam9zbGRoaSIsImEiOiJja2d0ZjdzbXAwMXdxMnNwN2Jkb2NvbXJ3In0.SayFfMYF2huWsZckbqNqEw" 
                alt="Static map image of Edmund Pettus Bridge in Selma, Alabama, using the Dark map style, with a black marker with the letter 'L' positioned in the middle of the bridge.">
            </img> */}
            <img 
              className='mt-4 sm:mt-12 rounded-xl  h-[300px] w-[400px] sm:h-[500px] sm:w-[800px]'
              src={LocationMap}
              alt='location'
            />
            <div className='sm:hidden flex flex-col w-full mt-4 text-center px-4'>
                <p className='leading-8 sm:leading-4 font-normal text-base text-[#757575]'>At NatureDots we are <strong>committed to achieving workforce diversity</strong> in terms of gender, LGBTQ, nationality, and <br></br> individuals from minority groups, indigenous groups and differently-abled persons are equally encouraged to apply!</p>
            </div>

        </div>
  )
}

export default TeamLocation