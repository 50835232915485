import React, { useEffect } from 'react';
import Navbar from '../components/Navbar/Navbar';
import MobileDropdown from '../components/Dropdown/MobileDropdown';
import SingleBlogsPage from '../components/BlogsDetailsPage/SingleBlogsPage';

function BlogsDetailsPage({ isOpen, toggleDropdown }) {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<>
			{!isOpen ? (
				<div className='flex flex-col w-full overflow-x-hidden'>
					<Navbar isOpen={isOpen} toggleDropdown={toggleDropdown} />
					<SingleBlogsPage />
				</div>
			) : (
				<MobileDropdown
					toggleDropdown={toggleDropdown}
					className='flex flex-col w-screen h-screen overflow-x-hidden'
				/>
			)}
		</>
	);
}

export default BlogsDetailsPage;
