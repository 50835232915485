import React from 'react'
import Trophy from "../../static/trophy.svg"
import Marquee from "react-fast-marquee";
import Marqueea from "../../static/marque1.svg"
import Marqueeb from "../../static/marque2.svg"
import Marqueec from "../../static/marque3.svg"
import Marqueed from "../../static/marque4.svg"
import Marqueee from "../../static/marque5.svg"

function AwardsSection() {
  return (
    <div className='flex flex-col justify-center items-center align-center w-full mt-[100px] sm:mt-60 bg-[#F3F3FB] py-[36px] sm:py-[90px]'>
      <p className='sm:hidden font-bold text-[24px] text-black w-full text-center'> AWARDS & RECOGNITION </p>
      <div className='hidden sm:flex align-center items-center mt-9'>
        <p className='font-bold text-4xl text-black w-full text-center'> AWARDS & RECOGNITION </p>
        <img className=' ml-8' src={Trophy} alt="Trophy"></img>
      </div>
      <Marquee className=' mt-[20px] sm:mt-24' speed={60} gradient={false}>
        <div className='flex space-x-14 align-center '>
          <img src={Marqueea} alt="picA" />
          <img src={Marqueeb} alt="picA" />
          <img src={Marqueec} alt="picA" />
          <img src={Marqueed} alt="picA" />
          <img src={Marqueee} alt="picA" />
        </div>
      </Marquee>
    </div>
  )
}

export default AwardsSection