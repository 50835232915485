import React, { useState } from 'react'
import Aquanurch from "../../static/AquaNurch.svg"
import Rocket from "../../static/rocket.svg"
import Product from "../../static/Product.png"
import "./OurSolution.css"
import { AiOutlineDown } from "react-icons/ai";
import { AiOutlineUp } from "react-icons/ai";


function OurSolution() {
    const [show, setShow] = useState(false);
    const [mobileFirst , setMobileFirst] = useState(false);
    const [mobileSecond , setMobileSecond] = useState(false);
    const [mobileThird , setMobileThird] = useState(false);
    return (
        <div className='flex flex-col justify-center items-center align-center w-full mt-20 bg-[#F3F3FB] sm:py-[60px]'>
            <div className='flex flex-col items-center mt-14 text-center'>
                <p className=' font-bold text-4xl text-black'>SOLUTION</p>
                <div className='flex items-center text-center my-[50px] '>
                    <img src={Aquanurch} alt="Logo" />
                </div>
                <div className='mx-4 sm:mx-[180px] text-center text-lg font-normal space-y-5 '>
                    <p>AquaNurch units acts as a Neural Node, capturing the pulse of water ecosystems from the depth and surface of water, combining it with the geospatial planetary spatial temporal data, processed using proprietary AI models.</p>
                    <p> The system acts as a Biophysical Chemical Ecological Big Data Factory on the natural system where it is deployed providing Resource Intelligence on localised, accurate, timely and simplified. Our data APIs are capable to provide primary onsite real-time data from 5-seconds to any defined intervals.</p>
                    {!show && <p onClick={() => setShow(true)}>Read More...</p>}
                    {show && <p>Our team then leverages massive data and other information on ecosystem health to navigate and generate customised usable, beneficial insights for different customers and use-cases, providing roster of services. In India, we are delivering solution – alerts, advisories, and insights in 13 languages; Globally in english via mobile and web dashboard.</p>}
                </div>
            </div>
            <div className='hidden sm:block py-[60px] text-center text-2xl font-normal'>
                <p>One solution, multiplying Impact</p>
            </div>
            <div className='flex justify-between w-[80%]'>
                <div className='scrollbar mt-[20px] hidden sm:flex flex-col items-center h-[500px] space-y-8 overflow-y-scroll pl-10' style={{ direction: 'rtl' }}>
                    <div className='flex justify-between space-x-16 items-center w-[517px]'>
                        <div className='flex flex-col items-start' style={{ direction: 'ltr' }}>
                            <div className='w-full border-b border-b-[#00208C]'>
                                <p className=' text-3xl font-bold text-[#00208C] pb-4'>Climate Change Resilience</p>
                            </div>
                            <p className=' leading-6  pt-4 text-base font-normal text-[#222222]'>AquaNurch® System installation acts as a network of neural node for capturing the pulse of water ecosystems on a real-time basis. It gathers ‘Resource Intelligence’ by capturing the interconnected biophysical, chemical, ecological and anthropogenic disturbances on waterscapes and associated landscapes, currently mapping – lakes, rivers, aquaculture ponds, marine, creek and mangroves.</p>
                            <p className=' leading-6  pt-4 text-base font-normal text-[#222222]'>The AquaNurch Ensembled Model® developed by R&D team of AquaNurch® System delivers to de-risk water bodies, aquatic life, people and business from risks of quality, quantity, climate induced and ecological driven hazards. Our AI model integrates different climate scenarios, ecological complexities, and weather patterns to estimate and forecast water health at varied geographical scale.</p>
                        </div>
                    </div>
                    <div className='flex justify-between space-x-16 items-center w-[517px]'>
                        <div className='flex flex-col items-start ' style={{ direction: 'ltr' }}>
                            <div className='w-full border-b border-b-[#00208C]'>
                                <p className=' text-3xl font-bold text-[#00208C] pb-4'>Informed Decision-Making</p>
                            </div>
                            <p className=' leading-6  pt-4 text-base font-normal text-[#222222]'>AquaNurch® AI-powered Engine gathers and processes millions of data points on ecological stressors from farm to mega water bodies, fishes, biodiversity, hydrology, landscape, climate variables and geospatial spatial temporal using proprietary AI AquaNurch® AI-powered Engine to assess current and forecast climate risks and variability enabling fish farmers and water system managers to take timely and effective measures to reduce vulnerability, catalyst resilience and enhance sustainable production.</p>
                            <p className=' leading-6  pt-4 text-base font-normal text-[#222222]'>AquaNurch® not only detects climate and ecological risks, and enhance the adaptation of fish farmers due to climate variability; but also its Nature-based component reduces heavy metal and pollution ensuring a healthy water ecosystem for healthy fisheries, protein and food security, and sustaining biodiversity.</p>
                            <p className=' leading-6  pt-4 text-base font-normal text-[#222222]'>Enabling fish farmers and water managers with the most advanced water information with user-friendly action-focused alerts and advisories in their local language, significantly improving decision making through data and process.</p>
                        </div>
                    </div>
                    <div className='flex justify-between space-x-16 items-center  w-[517px]'>
                        <div className='flex flex-col items-start' style={{ direction: 'ltr' }}>
                            <div className='w-full border-b border-b-[#00208C]'>
                                <p className=' text-3xl font-bold text-[#00208C] pb-4'>Water Health Restoration</p>
                            </div>
                            <p className=' leading-6 pt-4 text-base font-normal text-[#222222]'>Given the lack of water data and complexities of the water pollution issue, along with the high cost of water pollution removal, water managers have been facing a complex problem. AquaNurch® System provides past, present and future data analysis combined with AI and advanced ensembles of water and ecological models, providing targeted insights of water pollution, and forecast climate and weather scenarios.</p>
                            <p className=' leading-6 pt-4 text-base font-normal text-[#222222]'>The AquaNurch Ensembled Model® developed by R&D team of AquaNurch® System delivers to de-risk water bodies, aquatic life, people and business from risks of quality, quantity, climate induced and ecological driven hazards. Our AI model integrates different climate scenarios, ecological complexities, and weather patterns to estimate and forecast water health at varied geographical scale.</p>
                        </div>
                    </div>
                </div>
                <div className='flex flex-col items-center '>
                    <img src={Product} alt='product' />
                    <div className='flex text-center w-fit px-6 py-3 bg-[#00208C] rounded-3xl' onClick={() => window.open('https://www.aquanurch.com/overallsummary')}>
                        <img src={Rocket} alt="rocket" />
                        <p className=' text-base font-normal text-white ml-2'>View Product</p>
                    </div>
                </div>
            </div>
            <div className='sm:hidden flex flex-col w-full mt-8 border-t border-b border-[#00208C] py-5' onClick={() => setMobileFirst(!mobileFirst)}>
                <div className='flex justify-between w-full px-4'>
                    <p className=' text-lg font-bold text-[#00208C]'>Climate Change Resilience</p>
                    <div className='rounded-[50%] h-[28px] w-[28px] bg-[#00208C] flex items-center justify-center'>
                      { !mobileFirst && <AiOutlineDown className='text-white'/> }
                      { mobileFirst && <AiOutlineUp className='text-white'/> }
                    </div>
                </div>
                { mobileFirst && <p className=' leading-6  pt-4 text-sm font-normal text-[#222222] text-left px-4'>AquaNurch® System installation acts as a network of neural node for capturing the pulse of water ecosystems on a real-time basis. It gathers ‘Resource Intelligence’ by capturing the interconnected biophysical, chemical, ecological and anthropogenic disturbances on waterscapes and associated landscapes, currently mapping – lakes, rivers, aquaculture ponds, marine, creek and mangroves.</p>}
                { mobileFirst && <p className=' leading-6  pt-4 text-sm font-normal text-[#222222] text-left px-4'>The AquaNurch Ensembled Model® developed by R&D team of AquaNurch® System delivers to de-risk water bodies, aquatic life, people and business from risks of quality, quantity, climate induced and ecological driven hazards. Our AI model integrates different climate scenarios, ecological complexities, and weather patterns to estimate and forecast water health at varied geographical scale.</p>}
            </div>
            <div className='sm:hidden flex flex-col w-full  border-b border-[#00208C] py-5' onClick={() => setMobileSecond(!mobileSecond)}>
                <div className='flex justify-between w-full px-4'>
                    <p className=' text-lg font-bold text-[#00208C]'>Informed Decision-Making</p>
                    <div className='rounded-[50%] h-[28px] w-[28px] bg-[#00208C] flex items-center justify-center'>
                      { !mobileSecond && <AiOutlineDown className='text-white'/> }
                      { mobileSecond && <AiOutlineUp className='text-white'/> }
                    </div>
                </div>
                
                { mobileSecond && <p className=' leading-6  pt-4 text-sm font-normal text-[#222222] text-left px-4'>AquaNurch® AI-powered Engine gathers and processes millions of data points on ecological stressors from farm to mega water bodies, fishes, biodiversity, hydrology, landscape, climate variables and geospatial spatial temporal using proprietary AI AquaNurch® AI-powered Engine to assess current and forecast climate risks and variability enabling fish farmers and water system managers to take timely and effective measures to reduce vulnerability, catalyst resilience and enhance sustainable production.</p>}
                { mobileSecond &&  <p className=' leading-6  pt-4 text-base font-normal text-[#222222] text-left px-4'>AquaNurch® not only detects climate and ecological risks, and enhance the adaptation of fish farmers due to climate variability; but also its Nature-based component reduces heavy metal and pollution ensuring a healthy water ecosystem for healthy fisheries, protein and food security, and sustaining biodiversity.</p>}
                { mobileSecond &&  <p className=' leading-6  pt-4 text-base font-normal text-[#222222] text-left px-4'>Enabling fish farmers and water managers with the most advanced water information with user-friendly action-focused alerts and advisories in their local language, significantly improving decision making through data and process.</p>}
            </div>
            <div className='sm:hidden flex flex-col w-full  border-b border-[#00208C] py-5' onClick={() => setMobileThird(!mobileThird)}>
                <div className='flex justify-between w-full px-4'>
                    <p className=' text-lg font-bold text-[#00208C]'>Water Health Restoration</p>
                    <div className='rounded-[50%] h-[28px] w-[28px] bg-[#00208C] flex items-center justify-center'>
                      { !mobileThird && <AiOutlineDown className='text-white'/> }
                      { mobileThird && <AiOutlineUp className='text-white'/> }
                    </div>
                </div>
                { mobileThird &&  <p className=' leading-6 pt-4 text-base font-normal text-[#222222] text-left px-4'>Given the lack of water data and complexities of the water pollution issue, along with the high cost of water pollution removal, water managers have been facing a complex problem. AquaNurch® System provides past, present and future data analysis combined with AI and advanced ensembles of water and ecological models, providing targeted insights of water pollution, and forecast climate and weather scenarios.</p> }
                { mobileThird &&  <p className=' leading-6 pt-4 text-base font-normal text-[#222222] text-left px-4'>The AquaNurch Ensembled Model® developed by R&D team of AquaNurch® System delivers to de-risk water bodies, aquatic life, people and business from risks of quality, quantity, climate induced and ecological driven hazards. Our AI model integrates different climate scenarios, ecological complexities, and weather patterns to estimate and forecast water health at varied geographical scale.</p> }
            </div>
        </div>
    )
}

export default OurSolution