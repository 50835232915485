import React, { useState } from 'react';
import frame from '../../../static/Frame.svg';
import { useNavigate } from 'react-router-dom';

function PricingSection() {
	const [monthlySelected, setMonthlySelected] = useState(true);
	const [selectedCurrency, setSelectedCurrency] = useState('INR');
	const navigate = useNavigate();
	const plans = [
		{
			type: 'Basic',
			priceINRMonthly: '₹915',
			priceINRYearly: '₹9,780',
			priceDollerMothly: '$10.97',
			priceDollerYearly: '$117.2',
			disCountPriceINR: '₹12,225',
			disCountPriceDoller: '$146.5',
			yearlyDiscount: '20% discount',
			features: [
				{
					title: 'AquaNurch Digital Twin',
					detail: '(Every 30 days)',
					subFeature: ['Water Risk', 'Ecological Risk', 'Climate Risk'],
				},
				{ title: 'AquaNurch Membership', detail: '', subFeature: [] },
				{ title: 'E-Diary (Unlimited)', detail: '', subFeature: [] },
				{ title: 'Fish Doctor', detail: '(3 Entries/month)', subFeature: [] },
				{ title: 'Pond Management', detail: '(1 pond)', subFeature: [] },
				{ title: 'Training Modules', detail: '(Limited)', subFeature: [] },
				{ title: 'End to End Support', detail: '', subFeature: [] },
				// ... add other features here
			],
		},
		{
			type: 'Advance',
			priceINRMonthly: '₹1,830',
			priceINRYearly: '₹16,470',
			priceDollerMothly: '$21.93',
			priceDollerYearly: '$197.4',
			disCountPriceINR: '₹21,960',
			disCountPriceDoller: '$263.2',
			yearlyDiscount: '25% discount',
			features: [
				{
					title: 'AquaNurch Digital Twin',
					detail: '(Every 7 days)',
					subFeature: ['Water Risk', 'Ecological Risk', 'Climate Risk'],
				},
				{ title: 'AquaNurch Membership', detail: '', subFeature: [] },
				{ title: 'E-Diary (Unlimited)', detail: '', subFeature: [] },
				{ title: 'Fish Doctor', detail: '(10 Entries/month)', subFeature: [] },
				{ title: 'Pond Management', detail: '(5 ponds)', subFeature: [] },
				{ title: 'Market Information', detail: '', subFeature: [] },
				{ title: 'Government Schemes', detail: '', subFeature: [] },
				{ title: 'Training Modules', detail: '(Limited)', subFeature: [] },
				{ title: 'End to End Support', detail: '(Limited)', subFeature: [] },
				{
					title: 'Expert Visits & Consultations',
					detail: '(Limited)',
					subFeature: [],
				},
			],
		},
		{
			type: 'Premium',
			priceINRMonthly: '₹3,225',
			priceINRYearly: '₹27,090',
			priceDollerMothly: '$38.65',
			priceDollerYearly: '$371.1',
			disCountPriceINR: '₹38,700',
			disCountPriceDoller: '$463.8',
			yearlyDiscount: '30% discount',
			features: [
				{
					title: 'AquaNurch Digital Twin',
					detail: '(Daily basis)',
					subFeature: ['Water Risk', 'Ecological Risk', 'Climate Risk'],
				},
				{ title: 'AquaNurch Membership', detail: '', subFeature: [] },
				{ title: 'E-Diary (Unlimited)', detail: '', subFeature: [] },
				{ title: 'Fish Doctor', detail: '(Unlimited)', subFeature: [] },
				{ title: 'Pond Management', detail: '(20 ponds)', subFeature: [] },
				{ title: 'Market Information', detail: '', subFeature: [] },
				{
					title: 'Government Schemes',
					detail: '(with complete documentation support)',
					subFeature: [],
				},
				{ title: 'Training Modules', detail: '(Unlimited)', subFeature: [] },
				{ title: 'End to End Support', detail: '(Unlimited)', subFeature: [] },
				{
					title: 'Expert Visits & Consultations',
					detail: '(Unlimited)',
					subFeature: [],
				},
			],
		},
	];

	const PlanCard = ({ plan }) => (
		<div className='flex flex-col bg-white rounded-[12px] py-6 px-[34px] shadow-md space-y-4 cursor-pointer max-h-full w-full'>
			<p className='text-[20px] font-semibold text-[#231D4F]'>{plan.type}</p>
			{monthlySelected ? (
				<div className='flex items-center space-x-4'>
					<p className='text-[#00208C] font-bold text-[28px]'>
						{selectedCurrency === 'INR'
							? plan.priceINRMonthly
							: plan.priceDollerMothly}
					</p>
					<p className='text-[18px] text-[#757575] font-normal'>/month</p>
				</div>
			) : (
				<div>
					<div className='flex items-center space-x-4'>
						{' '}
						<p className='text-[#00208C] font-bold text-[28px]'>
							{selectedCurrency === 'INR'
								? plan.priceINRYearly
								: plan.priceDollerYearly}
						</p>
						<div className='bg-[#009900] px-2 py-1 flex items-center justify-center text-white text-xs font-medium rounded-[8px]'>
							{plan.yearlyDiscount}
						</div>
					</div>

					<div className='flex items-center space-x-4 mt-1'>
						<p className='text-[#00208C] font-bold text-[20px] line-through'>
							{selectedCurrency === 'INR'
								? plan.disCountPriceINR
								: plan.disCountPriceDoller}
						</p>
						<p className='text-[18px] text-[#757575] font-normal'>/Yearly</p>
					</div>
				</div>
			)}
			<div className='flex w-full justify-center'>
				<button
					className='bg-[#00208C] flex items-center justify-center py-1 px-10 text-white rounded-full w-fit'
					onClick={() =>
						document
							.getElementById('#submitForm')
							.scrollIntoView({ behavior: 'smooth' })
					}
				>
					Choose plan
				</button>
			</div>

			{plan.features.map((feature, index) => (
				<div key={index} className='flex flex-col space-y-4'>
					<div className='flex space-x-3 items-start'>
						<img src={frame} alt='frame' />
						<div className='flex flex-col'>
							<p className='text-[#757575] font-medium text-sm'>
								{feature.title}
							</p>
							<p className='text-[#757575] font-medium text-xs'>
								{feature.detail}
							</p>
						</div>
					</div>
					{feature.subFeature.length > 0 && (
						<div className='flex flex-col space-y-2 ml-10'>
							{feature.subFeature.map((subFeature, index) => (
								<li key={index} className='text-[#757575] font-medium text-xs'>
									{subFeature}
								</li>
							))}
						</div>
					)}
				</div>
			))}
		</div>
	);

	return (
		<div className='flex flex-col justify-center items-center align-center w-full mt-[100px] sm:mt-60 space-y-6'>
			<h1 className='font-black text-2xl sm:text-4xl text-black text-center uppercase'>
				Pricing
			</h1>
			<p className='text-[#757575] font-normal text-[18px] text-center'>
				{' '}
				Discover the ideal plan towards Climate Resilient High Fisheries Growth.
				<br></br>
				Our pricing options are carefully crafted to cater to businesses.
			</p>
			<div className='flex flex-col md:flex-row items-center space-y-2 md:space-y-0 md:space-x-[40px]'>
				<div className='flex items-center space-x-4 px-2 py-3 border border-[#E7EBFF] rounded-[10px] shadow-sm bg-[#FBFBFB]'>
					<div
						className={` px-6 py-3 rounded-[10px] cursor-pointer ${
							monthlySelected ? 'bg-[#00208C]' : ''
						}`}
						onClick={() => setMonthlySelected(true)}
					>
						<p
							className={`text-sm font-medium ${
								monthlySelected ? 'text-[#FFFFFF]' : 'text-[#121212]'
							}`}
						>
							Monthly
						</p>
					</div>
					<div
						className={` px-6 py-3 rounded-[10px] text-sm font-medium text-[#FFFFFF] cursor-pointer flex items-center space-x-2 ${
							monthlySelected ? '' : 'bg-[#00208C]'
						}`}
						onClick={() => setMonthlySelected(false)}
					>
						<p
							className={`text-sm font-medium ${
								monthlySelected ? 'text-[#121212]' : 'text-[#FFFFFF]'
							}`}
						>
							Yearly
						</p>
						<div className='bg-[#009900] px-2 py-1 rounded-lg text-white text-[xs]'>
							30% discount
						</div>
					</div>
				</div>
				<div className='flex items-center space-x-3'>
					<div class='inline-flex items-center'>
						<label
							class='relative flex items-center p-3 rounded-full cursor-pointer'
							htmlFor='html'
						>
							<input
								name='type'
								type='radio'
								class="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-full border border-blue-gray-200 text-[#00208C] transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-[#00208C] checked:before:bg-gray-900"
								id='inr'
								value='INR'
								checked={selectedCurrency === 'INR'}
								onChange={(e) => setSelectedCurrency(e.target.value)}
							/>
							<span class='absolute text-gray-900 transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100'>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									class='h-3.5 w-3.5'
									viewBox='0 0 16 16'
									fill='#00208C'
								>
									<circle data-name='ellipse' cx='8' cy='8' r='8'></circle>
								</svg>
							</span>
						</label>
						<label
							class='mt-px font-light text-gray-700 cursor-pointer select-none'
							htmlFor='html'
						>
							₹INR
						</label>
					</div>

					<div class='inline-flex items-center'>
						<label
							class='relative flex items-center p-3 rounded-full cursor-pointer'
							htmlFor='html'
						>
							<input
								name='type'
								type='radio'
								class="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-full border border-blue-gray-200 text-[#00208C] transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-[#00208C] checked:before:bg-gray-900"
								id='usd'
								value='USD'
								checked={selectedCurrency === 'USD'}
								onChange={(e) => setSelectedCurrency(e.target.value)}
							/>
							<span class='absolute text-gray-900 transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100'>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									class='h-3.5 w-3.5'
									viewBox='0 0 16 16'
									fill='#00208C'
								>
									<circle data-name='ellipse' cx='8' cy='8' r='8'></circle>
								</svg>
							</span>
						</label>
						<label
							class='mt-px font-light text-gray-700 cursor-pointer select-none'
							htmlFor='html'
						>
							$USD
						</label>
					</div>
				</div>
			</div>
			<div className='flex flex-col space-y-3'>
				<div className='flex flex-col sm:flex-row space-y-4 sm:space-y-0 items-start justify-between sm:space-x-[48px] max-h-full'>
					{plans.map((plan, index) => (
						<PlanCard key={index} plan={plan} />
					))}
				</div>
				<div className='flex items-center justify-between text-[#757575] text-xs sm:text-sm font-normal max-w-full'>
					<p>*Note: GST & Taxes applicable</p>
					<p
						className='cursor-pointer'
						onClick={() => navigate('/privacypolicy-app')}
					>
						Check privacy policy mobile app
					</p>
					<p
						className='cursor-pointer'
						onClick={() => navigate('/refundpolicy')}
					>
						Check refund policy
					</p>
				</div>
			</div>
		</div>
	);
}

export default PricingSection;
