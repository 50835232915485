import React, { useState } from 'react'
import Heart from "../../../static/Heart.svg"
import AquaNineColored from "../../../static/AquaNineColored.svg"
import AquaNineSimple from "../../../static/AquaNineSimple.svg"
import AquaTwoSimple from "../../../static/AquaTwoSimple.svg"
import AquaTwoColored from "../../../static/AquaTwoColored.svg"
import AquaSevenSimple from "../../../static/AquaSevenSimple.svg"
import AquaSevenColored from "../../../static/AquaSevenColored.svg"
import AquaTwentySimple from "../../../static/AquaTwentySimple.svg"
import AquaTwentyColored from "../../../static/AquaTwentyColored.svg"
import TwentyMobile from "../../../static/twentyMobile.svg"



function AquanurchImpact() {
    const [flagOne, setFlagOne] = useState(false);
    const [flagTwo, setFlagTwo] = useState(false);
    const [flagThree, setFlagThree] = useState(false);
    const [flagFour, setFlagFour] = useState(false);
    return (
        <div className='flex flex-col justify-center items-center align-center w-full mt-[100px] sm:mt-60'>
            <div className='flex align-center items-center'>
                <p className='font-extrabold text-4xl text-black w-full text-center'> IMPACT </p>
                <img className=' ml-8' src={Heart} alt="Heat"></img>
            </div>
            <p className='hidden sm:block text-base sm:text-lg font-normal text-[#757575] mt-8 px-4 w-[328px] sm:w-full sm:px-0 text-center'>Saving the eco-system, All resources at a time</p>
            <p className=' sm:hidden text-base sm:text-lg font-normal text-[#757575] mt-8 px-4 w-[328px] sm:w-full sm:px-0 text-center'>Saving the eco-system,<br></br> All resources at a time</p>
            <div className=' hidden sm:flex mt-24 justify-between align-center items-center space-x-20'>
                <div className='flex flex-col items-center align-center space-y-3 pt-5'>
                    <img src={flagOne ? AquaNineColored : AquaNineSimple} alt='twoColored' onMouseEnter={() => setFlagOne(true)} onMouseLeave={() => setFlagOne(false)}></img>
                    <p className=' text-base font-semibold text-center'>De-Risked Aquaculture <br></br>Production System</p>
                </div>
                <div className='flex flex-col items-center align-center space-y-3'>
                    <img src={flagTwo ? AquaTwoColored : AquaTwoSimple} alt='twoColored' onMouseEnter={() => setFlagTwo(true)} onMouseLeave={() => setFlagTwo(false)}></img>
                    <p className=' text-base font-semibold text-center'>Increased Fish Production</p>
                </div>
                <div className='flex flex-col items-center align-center space-y-3'>
                    <img src={flagThree ? AquaSevenColored : AquaSevenSimple} alt='twoColored' onMouseEnter={() => setFlagThree(true)} onMouseLeave={() => setFlagThree(false)}></img>
                    <p className=' text-base font-semibold text-center'>Improved Water Quality</p>
                </div>
                <div className='flex flex-col items-center align-center space-y-3 pt-5'>
                    <img src={flagFour ? AquaTwentyColored : AquaTwentySimple} alt='twoColored' onMouseEnter={() => setFlagFour(true)} onMouseLeave={() => setFlagFour(false)}></img>
                    <p className=' text-base font-semibold text-center'>Reduced Input Cost <br></br>for Fisheries</p>
                </div>
            </div>
            <div className='sm:hidden grid grid-cols-2 gap-8 mt-8 px-4'>
                <div className='flex flex-col items-center align-center space-y-3'>
                    <img src={flagOne ? AquaNineColored : AquaNineColored} alt='twoColored' onMouseEnter={() => setFlagOne(true)} onMouseLeave={() => setFlagOne(false)}></img>
                    <p className=' text-sm text-center font-semibold'>De-Risked Aquaculture <br></br>Production System</p>
                </div>
                <div className='flex flex-col items-center align-center space-y-3'>
                    <img src={flagTwo ?  TwentyMobile : TwentyMobile} alt='twoColored' onMouseEnter={() => setFlagTwo(true)} onMouseLeave={() => setFlagTwo(false)}></img>
                    <p className=' text-sm text-center font-semibold'>Increased Fish Production</p>
                </div>
                <div className='flex flex-col items-center align-center space-y-3'>
                    <img src={flagThree ? AquaSevenSimple : AquaSevenSimple} alt='twoColored' onMouseEnter={() => setFlagThree(true)} onMouseLeave={() => setFlagThree(false)}></img>
                    <p className=' text-sm text-center font-semibold'>Improved Water Quality</p>
                </div>
                <div className='flex flex-col items-center align-center space-y-3 '>
                    <img src={flagFour ? AquaTwentySimple : AquaTwentySimple} alt='twoColored' onMouseEnter={() => setFlagFour(true)} onMouseLeave={() => setFlagFour(false)}></img>
                    <p className=' text-sm text-center font-semibold'>Reduced Input Cost <br></br>for Fisheries</p>
                </div>
            </div>
        </div>
    )
}

export default AquanurchImpact