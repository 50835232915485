import React, { useState } from 'react'
import "./Careers.css"

function CareersSection() {
    return (
        <div className='w-full  sm:mt-20 items-center flex flex-col '>
            <div className='flex flex-col w-full mt-14 text-center'>
                <p className=' font-bold text-4xl text-black'>Careers</p>
                <p className=' hidden sm:block font-normal text-lg text-[#757575] mt-6'>If you are dedicated towards Climate Action - Nature + DeepTech Solutions, and want to apply you expertise towards developing <br></br> disruptive innovation, <strong>full-time or part-time</strong> or intern, feel free to reach out. Write to us at <strong>talents@naturedots.com</strong></p>
                <p className=' sm:hidden font-normal text-base text-[#757575] mt-6 text-center mx-5 leading-8'>If you are dedicated towards Climate Action - Nature + DeepTech Solutions, and want to apply you expertise towards developing disruptive innovation, <strong>full-time or part-time</strong> or intern, feel free to reach out. Write to us at <strong>talents@naturedots.com</strong></p>
            </div>
            <div className='w-full bg-[#F6F7FC] py-[60px] mt-[60px] flex justify-center relative'>
                <div className='grid grid-cols-1 sm:grid-cols-3 gap-[36px] w-fit blurDiv'>
                    <div className='flex flex-col p-6 w-[318px] h-[158px] bg-white hover:bg-[#00208C] rounded-2xl shadow mx-auto cursor-pointer' />
                    <div className='flex flex-col p-6 w-[318px] h-[158px] bg-white hover:bg-[#00208C] rounded-2xl shadow mx-auto cursor-pointer' />
                    <div className='flex flex-col p-6 w-[318px] h-[158px] bg-white hover:bg-[#00208C] rounded-2xl shadow mx-auto cursor-pointer' />
                    <div className='hidden sm:flex flex-col p-6 w-[318px] h-[158px] bg-white hover:bg-[#00208C] rounded-2xl shadow mx-auto cursor-pointer' />
                    <div className='hidden sm:flex flex-col p-6 w-[318px] h-[158px] bg-white hover:bg-[#00208C] rounded-2xl shadow mx-auto cursor-pointer' />
                    <div className='hidden sm:flex flex-col p-6 w-[318px] h-[158px] bg-white hover:bg-[#00208C] rounded-2xl shadow mx-auto cursor-pointer' />
                </div>
                <div className='premiumCard'>
                <p>More Jobs Coming Soon.....</p>
                <div className='premiumButton' onClick={() => window.open('https://www.linkedin.com/company/naturedots/jobs/')}>
                  LinkedIn Page
                </div>
              </div>
            </div>
        </div>
    )
}

export default CareersSection