import React from 'react'
import Product from "../../static/Product.png"
import Rocket from "../../static/rocket.svg"

function ViewProduct() {
  return (
    <div className=' mt-[100px] sm:mt-48 flex sm:flex-row flex-col align-center items-center justify-between bg-[#00208C]  sm:mx-[100px] px-[35px] sm:px-16 py-[33px] sm:py-0 sm:rounded-2xl '>
      <p className=' sm:hidden font-bold text-[20px] text-center text-white leading-9'>Ready to see AquaNurch in <br></br> <span className=' mt-6'>Action?</span></p>
      <img src={Product} className="sm:hidden" alt='product' />
      <div className=' flex flex-col align-center items-start space-y-6'>
        <p className=' hidden sm:block font-bold text-4xl text-white leading-9'>Ready to see AquaNurch in</p>
        <p className='hidden sm:block font-bold text-4xl text-white mb-2'>Action?</p>
        <p className=' text-center sm:text-left font-medium text-base text-[#ffffffbf] leading-9'>
          Very easy to install the setup into your Ponds,
          Lakes & Water Bodies. <br></br> With AquaNurch eliminate all the uncessary costs and track your pond <br></br>
          records & expenses online at oneplace by just a click.
        </p>
        <div className='hidden sm:flex align-center items-center justify-between px-6 py-3 bg-[#009900] rounded-3xl' onClick={() => window.open('https://www.aquanurch.com/overallsummary')}>
          <img src={Rocket} alt="rocket" />
          <p className=' text-base font-normal text-white ml-2'>View Product</p>
        </div>
      </div>
      <img src={Product} className="hidden sm:block" alt='product' />
      <div className='flex sm:hidden mt-5 align-center items-center justify-between px-6 py-3 bg-[#009900] rounded-3xl' onClick={() => window.open('https://www.aquanurch.com/overallsummary')}>
        <img src={Rocket} alt="rocket" />
        <p className=' text-base font-normal text-white ml-2'>View Product</p>
      </div>
    </div>
  )
}

export default ViewProduct