import React, { useEffect } from 'react'
import AquanurchLogo from "../../../static/AquaNurchLogo.svg"
import DuckPng from "../../../static/DuckPng.png"

function ProductHeading() {
    useEffect(() => {
        window.scrollTo(0,0)
    },[]);
    return (
        <div className='w-full mt-24 sm:mt-40 flex flex-col sm:flex-row sm:px-28 sm:justify-between items-center'>
            <div className='flex flex-col items-center sm:items-start'>
                <div className='flex text-center items-center font-bold text-4xl text-black'>
                    <img src={AquanurchLogo} alt='AquanurchLogo' className='sm:h-[92px]' />
                </div>
                <p className=' mt-4 leading-[150%]  font-bold text-2xl sm:text-4xl text-black'>Resource Intelligence</p>
                <p className='hidden sm:block mt-6 font-normal text-base sm:text-lg text-[#757575] sm:w-[529px] text-center sm:text-left mx-4 sm:mx-0'>Creating Digital Twin of all the Water Ecosystems of the World to establish climate resilient waterscapes and de-risk nature, people and business.</p>
                <div className=' hidden sm:block mt-8 px-6 py-4 bg-[#00208C] w-fit text-white rounded-3xl cursor-pointer' onClick={() => window.open('https://www.aquanurch.com/overallsummary')}>
                    <p>Get a Demo {'>>'} </p>
                </div>
            </div>

            <div className='my-6 sm:my-0'>
               <img src={DuckPng} alt='vector' />
            </div>
            <p className=' sm:hidden text-base font-normal leading-8 text-center w-[328px]'>Creating Digital Twin of all the Water Ecosystems of the World to establish climate resilient waterscapes and de-risk nature, people and business.</p>

            <div className=' sm:hidden mt-8 px-6 py-4 bg-[#00208C] w-fit text-white rounded-3xl cursor-pointer' onClick={() => window.open('https://www.aquanurch.com/overallsummary')}>
                    <p>Get a Demo {'>>'} </p>
            </div>

        </div>
    )
}

export default ProductHeading