import React from 'react'
import Carousal from './Carousal'

function AquanurchSystem() {
    return (
        <div className='flex flex-col justify-center items-center align-center w-full mt-[100px] sm:mt-60'>
            <p className='font-bold text-2xl sm:text-4xl text-black'>AQUANURCH SYSTEMS</p>
            {/* <p className='font-semibold text-lg mt-[50px] text-[#00208C] my-[30px] sm:my-0'>HOW IT WORKS</p> */}
            <Carousal />
        </div>
    )
}

export default AquanurchSystem