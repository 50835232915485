import React from 'react'
import Leaf from "../../static/Leaf.svg"

function InnovationSection() {
  return (
    <div className=' flex flex-col justify-center items-center text-center align-center w-full mt-[100px] sm:mt-40'>
         <p className=' font-bold text-4xl text-black w-full text-center'>INNOVATION</p>
         <p className=' text-center mt-14 font-normal text-2xl text-[#757575]'>Combines</p>
         <div className='flex align-center  space-x-5 sm:space-x-20 mt-12 mr-4 sm:mr-8'>
            <div className='flex flex-col text-right space-y-2 mt-4 font-bold text-xs sm:text-lg'>
                <p>Nature Based</p>
                <p>Systems & Solutions</p>
            </div>
            <img className='w-[60px] h-[60px] sm:w-[100px] sm:h-[100px]' src={Leaf} alt="leafSvg"/>
            <div className='flex flex-col text-left space-y-2 mt-4 font-bold text-xs sm:text-lg'>
                <p>Deep Tech</p>
                <p>AI / ML / Big Data</p>
            </div>
         </div>
         <p className=' hidden sm:block font-normal text-base text-[#757575] mt-12'>We are combining natural science of <strong>nature-based + nature-inspired</strong> systems and <br></br> solutions with the DeepTech tools for a climate resilient nature-positive economy</p>
         <p className='sm:hidden font-normal text-base text-[#757575] mt-12 mx-4'>We are combining natural science of <strong>nature-based + nature-inspired</strong> systems and solutions with the DeepTech tools for a climate resilient nature-positive economy</p>
    </div>
  )
}

export default InnovationSection