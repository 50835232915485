import React from 'react';
import Logo from '../../../static/NaturedotsLogo.svg';
import Privacy from '../../../static/Privacy.svg';
import { useNavigate } from 'react-router-dom';

function PolicyHeaderApp() {
	const [isHindiSelected, setIsHindiSelected] = React.useState(true);
	const navigate = useNavigate();
	return (
		<div className='flex flex-col'>
			<div className='sm:hidden px-4 flex flex-col space-y-4 mt-4'>
				<p className='text-[#9F9F9] text-base'>Agreement</p>
				<p className='text-[#000088] font-bold text-xl'>
					{isHindiSelected ? 'गोपनीयता नीति' : 'Legal - PRIVACY POLICY'}
				</p>
				<div className='flex items-center space-x-10 '>
					<label className='flex items-center space-x-2'>
						<input
							type='radio'
							value='option1'
							checked={isHindiSelected === true}
							onChange={() => setIsHindiSelected(true)}
							className='form-radio text-blue-500 cursor-pointer'
						/>
						<span>हिन्दी</span>
					</label>

					<label className='flex items-center space-x-2'>
						<input
							type='radio'
							value='option2'
							checked={isHindiSelected === false}
							onChange={() => setIsHindiSelected(false)}
							className='form-radio text-blue-500 cursor-pointer'
						/>
						<span>English</span>
					</label>
				</div>
			</div>
			<div className='h-[250px] w-full bg-[#F3F3FB] hidden sm:block'>
				<div className='flex px-20 py-5'>
					<img
						className=' cursor-pointer h-[50px]'
						src={Logo}
						alt='logoImg'
						onClick={() => navigate('/')}
					/>
					<div className='flex flex-col space-y-4 items-center flex-1'>
						<img
							className=' cursor-pointer h-[130px] w-[200px]'
							src={Privacy}
							alt='logoImg'
						/>
						<p className='text-[#000088] font-bold text-2xl'>
							{isHindiSelected ? 'गोपनीयता नीति' : 'Legal - PRIVACY POLICY'}
						</p>
						<div className='flex items-center space-x-10 '>
							<label className='flex items-center space-x-2'>
								<input
									type='radio'
									value='option1'
									checked={isHindiSelected === true}
									onChange={() => setIsHindiSelected(true)}
									className='form-radio text-blue-500 cursor-pointer'
								/>
								<span>हिन्दी</span>
							</label>

							<label className='flex items-center space-x-2'>
								<input
									type='radio'
									value='option2'
									checked={isHindiSelected === false}
									onChange={() => setIsHindiSelected(false)}
									className='form-radio text-blue-500 cursor-pointer'
								/>
								<span>English</span>
							</label>
						</div>
					</div>
				</div>
			</div>
			<div className='flex flex-col px-4 sm:px-20 py-5 h-full space-y-8 mt-5'>
				<div className='flex flex-col space-y-2'>
					<p className='text-sm font-normal leading-6 text-black'>
						{isHindiSelected
							? 'NatureDots Pvt. Ltd. अपने उपयोगकर्ताओं और ग्राहकों की गोपनीयता का सम्मान करता है, जिसके परिणामस्वरूप वर्तमान गोपनीयता नीति सूचना प्रौद्योगिकी अधिनियम, 2000 और उसमें बनाए गए नियमों के अनुपालन में तैयार की गई है।'
							: 'NatureDots Pvt. Ltd. respects the privacy of its users and customers, pursuant to which, the present privacy policy has been prepared in compliance with the Information Technology Act, 2000 and the rules made therein.'}
					</p>
					<p>
						{isHindiSelected
							? 'हम आपसे गोपनीयता नीति को ध्यान से पढ़ने का अनुरोध करते हैं। कृपया ध्यान दें कि यदि आप गोपनीयता नीति की शर्तों से सहमत नहीं हैं, तो आपको मोबाइल ऐप का उपयोग करने की अनुमति नहीं है।'
							: 'We request you to kindly read the Privacy Policy carefully. Please note that if you do not agree with the terms of the Privacy Policy, you are not allowed to access the mobile app.'}
						<br />
						<strong>
							{isHindiSelected
								? '`मैं स्वीकार करता हूं` पर क्लिक करके या इस ऐप को डाउनलोड, इंस्टॉल या अन्यथा एक्सेस या उपयोग करके,'
								: " By clicking on 'I Accept' or by downloading, installing, or otherwise accessing or using this App,"}
						</strong>{' '}
						{isHindiSelected
							? 'आप गोपनीयता नीति की निम्नलिखित नियमों और शर्तों से बाध्य होने के लिए सहमत हैं।'
							: 'you agree to be bound by the following terms and conditions of the Privacy Policy:'}
					</p>
				</div>
				<div className='flex flex-col space-y-3'>
					<div className='flex items-center w-full'>
						<p className='text-base font-semibold leading-6 text-black'>
							1. {isHindiSelected ? 'परिभाषा' : 'Definitions'}
						</p>
					</div>
					<div className='flex items-center w-full'>
						<p className='text-base font-semibold leading-6 text-black'>
							2. {isHindiSelected ? 'परिचय' : 'Introduction'}
						</p>
					</div>
					<div className='flex items-center w-full'>
						<p className='text-base font-semibold leading-6 text-black'>
							3.{' '}
							{isHindiSelected
								? 'जानकारी एकत्रित करना'
								: 'Information collected'}
						</p>
					</div>
					<div className='flex items-center w-full'>
						<p className='text-base font-semibold leading-6 text-black'>
							4.{' '}
							{isHindiSelected
								? 'जानकारी कैसे एकत्रित की जाती है'
								: 'How Information is collected'}
						</p>
					</div>
					<div className='flex items-center w-full'>
						<p className='text-base font-semibold leading-6 text-black'>
							5.{' '}
							{isHindiSelected
								? 'एकत्रित जानकारी का उपयोग'
								: 'Using information collected'}
						</p>
					</div>
					<div className='flex items-center w-full'>
						<p className='text-base font-semibold leading-6 text-black'>
							6.{' '}
							{isHindiSelected
								? 'जानकारी का प्रकटीकरण'
								: 'Disclosure of information'}
						</p>
					</div>
					<div className='flex items-center w-full'>
						<p className='text-base font-semibold leading-6 text-black'>
							7. {isHindiSelected ? 'तृतीय-पक्ष साइटें' : 'Third-Party sites'}
						</p>
					</div>
					<div className='flex items-center w-full'>
						<p className='text-base font-semibold leading-6 text-black'>
							8. {isHindiSelected ? 'शिकायत अधिकारी' : 'Grievance officer'}
						</p>
					</div>
					<div className='flex items-center w-full'>
						<p className='text-base font-semibold leading-6 text-black'>
							9. {isHindiSelected ? 'सामान्य' : 'General'}
						</p>
					</div>
				</div>
				<div className='flex flex-col text-black'>
					<p className='text-black font-bold text-xl uppercase'>
						1. {isHindiSelected ? 'परिभाषा' : 'Definitions'}
					</p>
					<div className='flex flex-col mt-1 text-sm font-normal leading-6 text-black ml-5'>
						<p>
							1.1.{' '}
							{isHindiSelected
								? 'इस नीति में, जब तक संदर्भ अन्यथा नहीं अपेक्षित करता है-'
								: 'In this policy, unless the context otherwise requires'}
							-
						</p>
						<p className='ml-2'>
							1.1.1.{' '}
							<span className='font-bold'>
								{isHindiSelected ? '`ऐप`' : '‘App’'}
							</span>{' '}
							{isHindiSelected
								? 'का अर्थ है नैचुरडॉट्स द्वारा उपयोगकर्ताओं से संग्रहीत सभी जानकारी और डेटा, जिसमें व्यक्तिगत जानकारी शामिल है।'
								: 'means the mobile application that is developed, controlled and owned by NatureDots for use and access by the User to avail goods and services provided by NatureDots.'}
						</p>
						<p className='ml-2'>
							1.1.2.{' '}
							<span className='font-bold'>
								{isHindiSelected ? 'जानकारी’' : '‘Information’'}
							</span>{' '}
							{isHindiSelected
								? 'का अर्थ है नैचुरडॉट्स द्वारा उपयोगकर्ताओं से संग्रहीत सभी जानकारी और डेटा, जिसमें व्यक्तिगत जानकारी शामिल है।'
								: 'means the mobile application that is developed, controlled and owned by NatureDots for use and access by the User to avail goods and services provided by NatureDots.'}
						</p>
						<p className='ml-2'>
							1.1.3.{' '}
							<span className='font-bold'>
								{isHindiSelected ? '‘नैचुरडॉट्स’' : '‘NatureDots’'}
							</span>{' '}
							{isHindiSelected
								? 'का अर्थ है नैचुरडॉट्स प्राइवेट लिमिटेड, जो कि कंपनियों अधिनियम, 2013 के तहत ए- 52, जीतर नगर, नई दिल्ली - 110051 में पंजीकृत है।'
								: 'means NatureDots Private Limited, a company registered under the Companies Act, 2013 at A-52, Jitar Nagar, New Delhi – 110051;'}
						</p>
						<p className='ml-2'>
							1.1.4.{' '}
							<span className='font-bold'>
								{isHindiSelected
									? '‘व्यक्तिगत जानकारी’'
									: '‘Personal information’'}
							</span>
							‘
							{isHindiSelected
								? ' का अर्थ है सूचना प्रौद्योगिकी (उचित सुरक्षा प्रथाएं और प्रक्रियाएं और संवेदनशील व्यक्तिगत डेटा या जानकारी) नियम, 2011 के नियम 3 में प्रदान की गई जानकारी। बशर्ते कि कोई भी जानकारी जो सार्वजनिक रूप से उपलब्ध या पहुंच योग्य है या सूचना का अधिकार अधिनियम, 2005 या किसी अन्य कानून के तहत प्रस्तुत की गई है, को व्यक्तिगत जानकारी के रूप में नहीं माना जाएगा।'
								: "means the information provided in Rule 3 of the Information Technology (Reasonable security practices and procedures and sensitive personal data or information) Rules, 2011. <br></br>{' '} Provided that any information that is publicly available or accessible or furnished under the Right to Information Act, 2005 or any other law shall not be deemed to be deemed as ‘personal information’;"}
						</p>
						<p className='ml-2'>
							1.1.5.
							<span className='font-bold'>
								{isHindiSelected ? '‘गोपनीयता नीति’' : '‘Privacy Policy’'}
							</span>{' '}
							{isHindiSelected
								? 'अर्थ है उपयोगकर्ताओं की व्यक्तिगत जानकारी के संग्रह, प्रकटीकरण और उपचार के बारे में विवरण प्रदान करने वाला वर्तमान दस्तावेज। यह नैचुरडॉट्स द्वारा इस गोपनीयता नीति में किए गए किसी भी बाद के परिवर्तनों को भी शामिल करता है।'
								: 'means the present document containing the details regarding collection, disclosure and treatment of personal information of the Users. It also includes any subsequent changes made by NatureDots in this Privacy Policy;'}
						</p>

						<p className='ml-2'>
							1.1.6.
							<span className='font-bold'>
								{isHindiSelected ? '‘उपयोग की शर्तें’' : '‘Terms of Use’'}{' '}
							</span>{' '}
							{isHindiSelected
								? 'का अर्थ है वेबसाइट पर प्रदान की गई वेबसाइट की नियम और शर्तें।'
								: 'means the Website’s terms and conditions provided at the website.'}
						</p>
						<p className='ml-2'>
							1.1.7.{' '}
							<span className='font-bold'>
								{isHindiSelected ? '‘उपयोगकर्ता’' : '‘User’'}{' '}
							</span>{' '}
							{isHindiSelected
								? 'का अर्थ है वेबसाइट का उपयोग करने वाला आगंतुक या व्यक्ति, साथ ही नैचुरडॉट्स का ग्राहक जो वेबसाइट का उपयोग कर रहा है।'
								: 'means the visitor or person using the Website as well as a customer of NatureDots who is using the Website;'}
						</p>
						<p className='ml-2'>
							1.1.8.{' '}
							<span className='font-bold'>
								{isHindiSelected ? 'वेबसाइट’' : '‘Website’'}
							</span>{' '}
							{isHindiSelected
								? 'का अर्थ है नैचुरडॉट्स की ऑनलाइन वेब पोर्टल है जिसका वेब लिंक है।'
								: 'is the online web portal of NatureDots having the web link at'}
							<span
								className='cursor-pointer ml-1 font-bold'
								onClick={() => navigate('/')}
							>
								www.naturedots.com
							</span>
						</p>
					</div>
				</div>

				<div className='flex flex-col text-black'>
					<p className='text-black font-bold text-xl'>
						2. {isHindiSelected ? 'परिचय' : 'INTRODUCTION'}
					</p>
					<div className='flex flex-col mt-1 text-sm font-normal leading-6 text-black ml-5'>
						<p>
							2.1.{' '}
							{isHindiSelected
								? 'नैचुरडॉट्स उपयोगकर्ता की गोपनीयता बनाए रखने के महत्व को पहचानता है। यह गोपनीयता नीति बताती है कि नैचुरडॉट्स ऐप पर एकत्र की गई उपयोगकर्ता की जानकारी का कैसे उपचार करता है। यह गोपनीयता नीति हमारी ऐप के वर्तमान और पूर्व उपयोगकर्ताओं और हमारे ऑनलाइन ग्राहकों पर लागू होती है जो ऐप का उपयोग कर रहे हैं। हमारी ऐप पर जाकर और/या उसका उपयोग करके, उपयोगकर्ता इस गोपनीयता नीति और यहां दी गई नियमों और शर्तों से सहमत होता है। यदि उपयोगकर्ता यहां दी गई किसी भी नियम और शर्त से असहमत है, तो उपयोगकर्ता को ऐप पर जाने और/या उसका उपयोग करने की अनुमति नहीं दी जाएगी।'
								: 'NatureDots recognizes the importance of maintaining the Users privacy. This Privacy Policy describes how NatureDots treats the Users information collected on the App. This Privacy Policy applies to current and former Users to our Website and to our online customers that are using the App. By visiting and/or using our App, the User agrees to this Privacy Policy and the terms and conditions herein. In case the User disagrees with any of the terms and conditions herein, then the User shall not be allowed to visit and/or use the App.'}
						</p>
						<p>
							2.2.{' '}
							{isHindiSelected
								? 'यह गोपनीयता नीति सूचना प्रौद्योगिकी अधिनियम, 2000 और इसके विभिन्न नियमों (जैसा कि संशोधित किया गया है), जैसे सूचना प्रौद्योगिकी (उचित सुरक्षा प्रथाएं और प्रक्रियाएं और संवेदनशील व्यक्तिगत डेटा या जानकारी) नियम, 2011 और सूचना प्रौद्योगिकी (मध्यस्थ दिशानिर्देश) नियम, 2011 और भारत में लागू किसी अन्य कानून के अनुसार तैयार की गई है।'
								: 'The Privacy Policy are formulated pursuant to the Information Technology Act, 2000 and its various rules (as amended), such as the Information Technology (Reasonable security practices and procedures and sensitive personal data or information) Rules, 2011 and the Information Technology (Intermediaries guidelines) Rules, 2011 and any other applicable laws in India.'}
						</p>
						<p>
							2.3.{' '}
							{isHindiSelected
								? 'सूचना प्रौद्योगिकी (उचित सुरक्षा प्रथाएं और प्रक्रियाएं और संवेदनशील व्यक्तिगत डेटा या जानकारी) नियम, 2011 के नियम 4 के अनुसार, यह वर्तमान गोपनीयता नीति नैचुरडॉट्स द्वारा विकसित, स्वामित्व और नियंत्रित ऐप पर उपलब्ध है और इसमें संग्रहीत व्यक्तिगत जानकारी के प्रकार, ऐसी व्यक्तिगत जानकारी के उद्देश्य और उपयोग, ऐसी व्यक्तिगत जानकारी के प्राधिकृत प्रकटीकरण और उपयोगकर्ता की व्यक्तिगत जानकारी को सुरक्षित करने के लिए उचित सुरक्षा प्रथाएं और प्रक्रियाएं सूचीबद्ध हैं।'
								: 'As per Rule 4 of the Information Technology (Reasonable security practices and procedures and sensitive personal data or information) Rules, 2011, the present Privacy Policy is available on the App developed, owned and controlled by NatureDots and lists the types of personal information collected, the purpose and usage of such personal information, authorized disclosure of such personal information and the reasonable security practices and procedures to protect personal information of the User.'}
						</p>
						<p>
							2.4.{' '}
							{isHindiSelected
								? 'यह गोपनीयता नीति हमारे व्यावसायिक भागीदारों, कॉर्पोरेट सहयोगियों या किसी अन्य तृतीय पक्ष पर लागू नहीं होती है, भले ही उनकी वेबसाइट या मोबाइल एप्लिकेशन साइट से जुड़ी हों। उपयोगकर्ता स्वीकार करता है और पुष्टि करता है कि नैचुरडॉट्स नैचुरडॉट्स व्यावसायिक भागीदारों, कॉर्पोरेट सहयोगियों या किसी अन्य तृतीय पक्ष द्वारा गैरकानूनी या अनधिकृत रूप से प्रकट/दुरुपयोग की गई किसी भी जानकारी के लिए जिम्मेदार नहीं होगा।'
								: 'This Privacy Policy does not apply to our business partners, corporate affiliates or to any other third parties, even if their Websites or mobile application are linked to the site. The User acknowledges and affirms that NatureDots shall not liable in case any information is unlawfully or unauthorizedly disclosed/misused by NatureDots business partners, corporate affiliates or any other third-party.'}
						</p>
					</div>
				</div>

				<div className='flex flex-col text-black'>
					<p className='text-black font-bold text-xl'>
						3.{' '}
						{isHindiSelected
							? 'एकत्रित की गई जानकारी'
							: 'INFORMATION COLLECTED'}
					</p>
					<div className='flex flex-col mt-1 text-sm font-normal leading-6 text-black ml-5'>
						<p>
							3.1.{' '}
							{isHindiSelected
								? 'वेबसाइट का उपयोग करने और उपयोग करने के लिए, नैचुरडॉट्स उपयोगकर्ता की व्यक्तिगत जानकारी सहित जानकारी एकत्रित कर सकता है।'
								: 'For the User to gain access and use the App, NatureDots shall collect Information of the User including Personal Information.'}
						</p>
						<p>
							3.2.{' '}
							{isHindiSelected
								? 'नैचुरडॉट्स द्वारा एकत्रित की गई व्यक्तिगत जानकारी में नाम, संपर्क नंबर, स्थान शामिल हैं, लेकिन इन तक सीमित नहीं हैं। सार्वजनिक डोमेन में पहले से मौजूद कोई भी व्यक्तिगत जानकारी व्यक्तिगत नहीं मानी जाएगी।'
								: 'Personal Information collected by NatureDots includes but is not limited to name, contract number, location. Any Personal Information which are already in the public domain shall not be considered personal'}
						</p>
						<p>
							3.3.{' '}
							{isHindiSelected
								? 'नैचुरडॉट्स द्वारा एकत्रित की गई गैर-व्यक्तिगत जानकारी में उपयोगकर्ता की वाणिज्यिक जानकारी (आय, व्यय, व्यावसायिक संचालन सहित), सुधार के लिए प्रतिक्रिया, तालाब डेटा, ईडायरी डेटा, मछली डॉक्टर में मछली व्यवहार इनपुट शामिल हैं, लेकिन इन तक सीमित नहीं हैं। यह जानकारी धारा 3.2 में निर्दिष्ट व्यक्तिगत जानकारी नहीं होगी।'
								: 'Non-personal information collected by NatureDots includes but is not limited to commercial information of the User (including, income, expenses, business operations), feedbacks for improvement, pond data, ediary data, fish behavior inputs in fish doctor. This information shall not be Personal Information as specified in Clause 3.2.'}
						</p>
						<p>
							3.4.{' '}
							{isHindiSelected
								? 'इसके अलावा, हम उपयोगकर्ताओं से अन्य जानकारी भी एकत्रित कर सकते हैं, जिसमें उपकरण जानकारी, मोबाइल नेटवर्क जानकारी, ऑपरेटिंग सिस्टम, लॉग जानकारी, समय क्षेत्र, आईपी पता, ब्राउज़र, कुकीज़, पाठ संदेश, साझा की गई सामग्री, ऐप में एनालिटिक्स शामिल हैं जो ऐप और गतिविधियों (क्लिक किए गए बटन आदि) पर उपयोगकर्ता के व्यवहार को ट्रैक करते हैं, और उपयोग की गई तस्वीर।'
								: 'Additionally, we may also collect other Information from the Users including but not limited to, device information, mobile network information, operating system, log information, time zone, IP address, the browser, cookies, text messages, shared content, analytics in App to track user behavior across the App and activities (which buttons clicked, etc.) picture which is used.'}
						</p>
						<p>
							3.5.{' '}
							{isHindiSelected
								? 'नैचुरडॉट्स उन उपयोगकर्ताओं से एकत्रित की गई किसी भी जानकारी को उस उद्देश्य के अनुरूप और लागू कानून के अनुसार जितनी देर तक आवश्यक या अनुमत हो, उतनी देर तक रखेगा जिसके लिए यह प्राप्त किया गया था। नैचुरडॉट्स अपने कानूनी दायित्वों का पालन करने, विवादों के समाधान और कानूनी समझौतों और नीतियों को लागू करने के लिए अपने उपयोगकर्ताओं से एकत्रित की गई किसी भी जानकारी को रखने का अधिकार सुरक्षित रखता है। नैचुरडॉट्स अपने उपयोगकर्ताओं से एकत्रित की गई किसी भी जानकारी को आंतरिक विश्लेषण उद्देश्यों के लिए रखने का भी अधिकार सुरक्षित रखता है।'
								: 'NatureDots will retain any information collected from its Users as long as needed or permitted in light of the purpose for which it was obtained and consistent with applicable law. NatureDots reserve the right to retain any information collected from its Users to comply with its legal obligations, resolution of disputes and enforce legal agreements and policies. NatureDots also reserves the right to retain any information collected from its Users for internal analysis purposes.'}
						</p>
						<p>
							3.6.{' '}
							{isHindiSelected
								? 'सभी जानकारी को नैचुरडॉट्स द्वारा इस गोपनीयता नीति और कानून के अनुसार अनधिकृत स्रोतों से सभी उचित सुरक्षा उपायों और प्रथाओं से संरक्षित किया जाएगा, डेटा सटीकता बनाए रखा जाएगा और जानकारी के उचित उपयोग/प्रकटीकरण को सुनिश्चित किया जाएगा। उपयोगकर्ता स्वीकार करता है और पुष्टि करता है कि नैचुरडॉट्स द्वारा किसी भी जानकारी की पूर्ण सुरक्षा को सुनिश्चित करने के लिए लिए गए उत्कृष्ट सुरक्षा उपायों के बावजूद, नैचुरडॉट्स ऐसी जानकारी की पूर्ण सुरक्षा की गारंटी नहीं देता है।'
								: 'All Information including Personal Information shall be protected by NatureDots with all the reasonable security measures and practices from unauthorized sources, maintain data accuracy and help ensure the appropriate use/disclosure of Information as required under this Privacy Policy and law. The User acknowledges and affirms that in spite of the excellent security measures taken by NatureDots to protect any information, NatureDots does not guarantee of such Informations absolute security.'}
						</p>
					</div>
				</div>

				<div className='flex flex-col text-black'>
					<p className='text-black font-bold text-xl'>
						4.{' '}
						{isHindiSelected
							? 'जानकारी कैसे एकत्र की जाती है'
							: 'HOW INFORMATION IS COLLECTED'}
					</p>
					<div className='flex flex-col mt-1 text-sm font-normal leading-6 text-black ml-5'>
						<p>
							4.1.{' '}
							{isHindiSelected
								? 'नैचुरडॉट्स अपनी सेवाएं और उत्पाद प्रदान करने के लिए उपयोगकर्ता के बारे में कोई भी जानकारी एकत्रित करेगा। यह ऐप का उपयोग करते समय या ऐप का लाभ उठाते समय उपयोगकर्ता द्वारा प्रदान किए गए डेटा, साइन अप पेज, ईडायरी डेटा, तालाब डेटा, मछली डॉक्टर के लिए मछली व्यवहार के माध्यम से किया जा सकता है।'
								: 'NatureDots shall collect any information about the User to provide its services and products. This may be done through the data given by the User directly while accessing or availing the App, during sign up page, ediary data, pond data, fish behavior for fish doctor through the App'}
						</p>
						<p>
							4.2.{' '}
							{isHindiSelected
								? 'इसके अलावा, नैचुरडॉट्स गूगल एनालिटिक्स, मिक्सपैनल आदि जैसे API के माध्यम से तीसरे पक्षों से उपयोगकर्ता के बारे में कोई भी जानकारी एकत्रित कर सकता है।'
								: 'Moreover, NatureDots may collect any Information about the User through third-parties through APIs like Google Analytics, Mixpanel, etc.'}
						</p>
					</div>
				</div>

				<div className='flex flex-col text-black'>
					<p className='text-black font-bold text-xl'>
						5.{' '}
						{isHindiSelected
							? 'जानकारी का उपयोग'
							: 'USING INFORMATION COLLECTED'}
					</p>
					<div className='flex flex-col mt-1 text-sm font-normal leading-6 text-black ml-5'>
						<p>
							5.1.{' '}
							{isHindiSelected
								? 'नैचुरडॉट्स अपने उपयोगकर्ता से एकत्रित की गई जानकारी, जिसमें व्यक्तिगत जानकारी भी शामिल है, का उपयोग निम्नलिखित उद्देश्यों के लिए करेगा:'
								: 'NatureDots shall use the Information including Personal Information so collected from its User:'}
						</p>
						<p className='ml-2'>
							5.1.1.{' '}
							{isHindiSelected
								? 'उपयोगकर्ता या किसी अन्य व्यक्ति को अपनी सेवाएं और उत्पाद प्रदान करने के लिए;'
								: 'To provide its services and products to the User or any other person;'}
						</p>
						<p className='ml-2'>
							5.1.2.{' '}
							{isHindiSelected
								? 'ग्राहक सहायता प्रदान करने और किसी भी शिकायत या प्रतिक्रिया का प्रबंधन करने के लिए;'
								: 'To provide customer support and handle any complaints or feedback'}
						</p>
						<p className='ml-2'>
							5.1.3.{' '}
							{isHindiSelected
								? 'नैचुरडॉट्स की सेवाओं और उत्पाद के उपयोग की निगरानी करने के लिए'
								: 'To monitor the usage of services and product of NatureDots'}
						</p>
						<p className='ml-2'>
							5.1.4.{' '}
							{isHindiSelected
								? 'आपको नैचुरडॉट्स द्वारा प्रस्तावित अन्य वस्तुओं, सेवाओं और घटनाओं के बारे में समाचार, विशेष प्रस्ताव और सामान्य जानकारी प्रदान करने के लिए, बशर्ते कि उपयोगकर्ता ने ऐसी अपडेट प्राप्त करने से मना नहीं किया हो;'
								: 'To provide you with news, special offer and general information about other goods and services and events that NatureDots offer and are similar to those already availed Provided that NatureDots shall not use the information for such purpose in case the User has opted to not receive any updates.'}
						</p>
						<p className='ml-2'>
							5.1.5.{' '}
							{isHindiSelected
								? 'उपयोगकर्ता या किसी अन्य व्यक्ति द्वारा किए गए किसी भी प्रश्न, अनुरोध या टिप्पणी का जवाब देने के लिए'
								: 'To respond to any question, requests or comment made by the User or any other person'}
						</p>
						<p className='ml-2'>
							5.1.6.{' '}
							{isHindiSelected
								? 'उत्पादों और सेवाओं को और अधिक सुधारने और उन्हें और अधिक अनुकूलित करने के लिए'
								: 'To further improve on the products and services and to further customize them'}
						</p>
						<p className='ml-2'>
							5.1.7.{' '}
							{isHindiSelected
								? 'वेबसाइट और ऐप को बेहतर बनाने और उपयोगकर्ता द्वारा प्रदान की गई जानकारी और तृतीय-पक्षों से उपयोगकर्ता के बारे में जानकारी को संयोजित करने के लिए'
								: 'To make the Website and App better and combine information provided by the User and information about the User from third-parties'}
						</p>
						<p className='ml-2'>
							5.1.8.{' '}
							{isHindiSelected
								? 'नैचुरडॉट्स, उसके निदेशकों, कर्मचारियों, एजेंटों, ग्राहकों, वेबसाइट और किसी भी उत्पाद और सेवाओं की रक्षा करने के लिए जो नैचुरडॉट्स प्रदान करता है'
								: 'To protect NatureDots, its directors, employees, agents, customers, Website and any product and services which NatureDots provide'}
						</p>
						<p className='ml-2'>
							5.1.9.{' '}
							{isHindiSelected
								? 'विशेष जानकारी प्रदान करने या विपणन उद्देश्यों के लिए'
								: 'To provide special information or for marketing purposes'}
						</p>
						<p className='ml-2'>
							5.1.10.{' '}
							{isHindiSelected
								? 'लेन-देन के उद्देश्यों के लिए, और'
								: 'For transactional purposes, and'}
						</p>
						<p className='ml-2'>
							5.1.11.{' '}
							{isHindiSelected
								? 'कानून द्वारा अन्यथा अनुमत या आवश्यक होने पर।'
								: 'As otherwise permitted or required under law'}
						</p>
					</div>
				</div>

				<div className='flex flex-col text-black'>
					<p className='text-black font-bold text-xl'>
						6.{' '}
						{isHindiSelected
							? 'जानकारी का प्रकटीकरण'
							: 'DISCLOSURE OF INFORMATION'}
					</p>
					<div className='flex flex-col mt-1 text-sm font-normal leading-6 text-black ml-5'>
						<p>
							6.1.{' '}
							{isHindiSelected
								? 'नैचुरडॉट्स अपने उपयोगकर्ता से एकत्र की गई किसी भी जानकारी (व्यक्तिगत जानकारी सहित) को साझा/हस्तांतरित/सौंप सकता है:'
								: 'NatureDots may share/transfer/assign any information (including personal information) collected from its User:'}
						</p>
						<p className='ml-2'>
							6.1.1.{' '}
							{isHindiSelected
								? 'उन विक्रेताओं और खुदरा व्यापारियों के साथ जो नैचुरडॉट्स को अपने ग्राहकों, जिसमें उपयोगकर्ता भी शामिल हैं, को अपने उत्पाद और सेवाएं प्रदान करने में मदद करते हैं;'
								: 'With vendors and retailers who help NatureDots in providing its products and services to its customers including the User;'}
						</p>
						<p className='ml-2'>
							6.1.2.{' '}
							{isHindiSelected
								? 'उन इवेंट आयोजकों और अन्य पक्षों के साथ जो NatureDots के उपयोगकर्ता के प्रति दायित्वों को पूरा करने के लिए जिम्मेदार हैं;'
								: 'With event organizers and other parties responsible for fulfilling NatureDots obligation towards its User'}
						</p>
						<p className='ml-2'>
							6.1.3.{' '}
							{isHindiSelected
								? 'नैचुरडॉट्स के व्यावसायिक साझेदारों के साथ, जिनमें वे तीसरे पक्ष शामिल हैं जो किसी इवेंट का प्रायोजन या प्रायोजन करते हैं, या जहां NatureDots इवेंट आयोजित करता है। ये तीसरे पक्ष अपने संबंधित गोपनीयता नीति के अनुसार प्राप्त की गई जानकारी का उपयोग कर सकते हैं;'
								: 'With business partners of NatureDots including third-parties who provide or sponsor an event, or who operates a venue where NatureDots hold events. These third-parties may utilize the information so received according to their respective Privacy Policy'}
						</p>
						<p className='ml-2'>
							6.1.4.{' '}
							{isHindiSelected
								? 'कानून के तहत अनुरोधित और आवश्यक होने पर कानून प्रवर्तन एजेंसियों, न्यायिक निकायों, सरकारी निकायों या किसी अन्य सार्वजनिक कार्यालय के साथ;'
								: 'With the law enforcement agencies, judicial bodies, government bodies or any other public office as requested and required under law'}
						</p>
						<p className='ml-2'>
							6.1.5.{' '}
							{isHindiSelected
								? 'नैचुरडॉट्स के उत्तराधिकारी के साथ'
								: 'With the successor of NatureDots'}
						</p>
						<p>
							6.2.{' '}
							{isHindiSelected
								? 'यदि धारा 6.1 में उल्लिखित कारणों के अलावा किसी अन्य कारण से नैचुरडॉट्स उपयोगकर्ता से एकत्रित की गई किसी भी जानकारी, जिसमें व्यक्तिगत जानकारी शामिल है, को साझा/स्थानांतरित/सौंपता है, तो वह जानकारी के प्रकटीकरण के कारणों का उल्लेख करते हुए उपयोगकर्ता को सूचित करेगा और उपयोगकर्ता को नैचुरडॉट्स द्वारा किए जाने वाले ऐसे प्रकटीकरण को अस्वीकार करने का विकल्प देगा। यदि उपयोगकर्ता ऐसे प्रकटीकरण से इनकार करता है, तो नैचुरडॉट्स अपने अधिकार को आरक्षित रखेगा कि वह उपयोगकर्ता को सेवाएं प्रदान करने से इनकार कर दे।'
								: 'NatureDots may share/transfer/assign any Information including Personal Information collected from the User for any other reason not mentioned under Clause 6.1. Provided in case of disclosure under Clause 6.2, NatureDots shall inform the User of the disclosure of Information while stating the reasons behind the same and allowing an option to the User to deny such disclosure by NatureDots. In case the User denies such disclosure, NatureDots shall reserve its right to provide services to the User.'}
						</p>
					</div>
				</div>

				<div className='flex flex-col text-black'>
					<p className='text-black font-bold text-xl'>
						7. {isHindiSelected ? 'तृतीय-पक्ष साइटें' : 'THIRD-PARTY SITES'}
					</p>
					<div className='flex flex-col mt-1 text-sm font-normal leading-6 text-black ml-5'>
						<p>
							7.1.{' '}
							{isHindiSelected
								? 'यदि उपयोगकर्ता किसी भी तृतीय-पक्ष वेबपेज के लिंक पर क्लिक करता है, तो उसे उस विशिष्ट वेबपेज पर भेजा जाएगा जो नैचुरडॉट्स के नियंत्रण और पर्यवेक्षण के अधीन नहीं है। गोपनीयता नीति उन वेबपेज पर लागू नहीं होगी जिन्हें संबंधित तृतीय-पक्ष की गोपनीयता नीति द्वारा शासित किया जाएगा।'
								: 'In case the User clicks any third-party webpages link, it will be directed to that particular webpage which is not under control and supervision of NatureDots. Privacy Policy will not apply to those webpages which would be governed by the respective third-partys Privacy Policy.'}
						</p>
						<p>
							7.2.{' '}
							{isHindiSelected
								? 'किसी भी तरह से नैचुरडॉट्स इन तृतीय-पक्ष वेबसाइटों के लिए जिम्मेदार नहीं होगा। उपयोगकर्ता स्वीकार करता है कि यदि किसी तृतीय-पक्ष वेबसाइट द्वारा गोपनीयता का उल्लंघन होता है, तो नैचुरडॉट्स को इसके लिए जिम्मेदार नहीं ठहराया जाएगा।'
								: 'NatureDots in any way whatsoever shall not be responsible for these third-party Websites. The User acknowledges that in case of breach of privacy by a third-party website, NatureDots shall not be held liable for the same.'}
						</p>
					</div>
				</div>

				<div className='flex flex-col text-black'>
					<p className='text-black font-bold text-xl'>
						8. {isHindiSelected ? 'शिकायत अधिकारी' : 'GRIEVANCE OFFICER'}
					</p>
					<div className='flex flex-col mt-1 text-sm font-normal leading-6 text-black ml-5'>
						<p>
							8.1.{' '}
							{isHindiSelected
								? 'सूचना प्रौद्योगिकी अधिनियम, 2000 और इसके तहत बनाए गए नियमों के अनुसार, उपयोगकर्ता नैचुरडॉट्स की शिकायत समिति से संपर्क कर सकते हैं जो उपलब्ध है '
								: 'In pursuant to the Information Technology Act, 2000 and the rules made thereunder, the User may get in touch with the grievance committee of the NatureDots available at '}
							<span className='underline text-blue-400'>
								reachus@naturedots.com
							</span>
							.
						</p>
					</div>
				</div>

				<div className='flex flex-col text-black'>
					<p className='text-black font-bold text-xl'>
						9. {isHindiSelected ? 'सामान्य' : 'GENERAL'}
					</p>
					<div className='flex flex-col mt-1 text-sm font-normal leading-6 text-black ml-5'>
						<p>
							9.1.{' '}
							{isHindiSelected
								? 'गोपनीयता नीति आखिरी बार 01/08/2024 को अपडेट की गई थी। समय-समय पर, नैचुरडॉट्स गोपनीयता नीति में परिवर्तन कर सकता है जिसके मामले में, कानून द्वारा आवश्यक होने पर उपयोगकर्ता को किसी भी महत्वपूर्ण परिवर्तन के बारे में सूचित किया जाएगा। नैचुरडॉट्स अपडेट की गई प्रति को वेबसाइट पर भी पोस्ट करेगा। कृपया अपडेट के लिए वेबसाइट को समय-समय पर देखें।'
								: 'Privacy Policy was last updated on 01/08/2024. From time to time, NatureDots may change the Privacy Policy. In such cases, the User will be notified of any material changes as required by law. NatureDots will also post an updated copy on the Website. Please check the Website periodically for updates.'}
						</p>
						<p>
							9.2.{' '}
							{isHindiSelected
								? 'यदि उपयोगकर्ता वेबसाइट का दौरा करने का विकल्प चुनता है, तो उसका दौरा और गोपनीयता पर कोई भी विवाद गोपनीयता नीति और उपयोग की शर्तों के अधीन होगा। उपर्युक्त के अतिरिक्त, गोपनीयता नीति के तहत उत्पन्न होने वाले किसी भी विवाद पर भारत के कानूनों का शासन होगा और गोपनीयता नीति से उत्पन्न होने वाले विवादों को सुलझाने के लिए दिल्ली के न्यायालयों के पास विशेष अधिकार होगा।'
								: 'If the User chooses to visit the Website, its visit and any dispute over privacy are subject to the Privacy Policy and the Terms of Use. Additionally, any disputes arising under the Privacy Policy shall be governed by the laws of India, and the courts of New Delhi shall have exclusive jurisdiction to adjudicate disputes arising out of the Privacy Policy.'}
						</p>
					</div>
				</div>
			</div>

			<div className='flex items-center w-full justify-center py-8 space-x-8'>
				<div
					className='px-[28px] py-3 flex items-center justify-center rounded-3xl text-black text-sm border border-[#00208C] cursor-pointer'
					onClick={() => navigate('/')}
				>
					Close
				</div>
				<div
					className='px-[28px] py-3 flex items-center justify-center rounded-3xl text-white text-sm border bg-[#00208C] cursor-pointer'
					onClick={() => navigate('/')}
				>
					Accept
				</div>
			</div>
		</div>
	);
}

export default PolicyHeaderApp;
