import { React, useState } from 'react'
import Heart from "../../static/Heart.svg"
import "./OurImpact.css"
import TwoColored from "../../static/twoColored.svg"
import TwoColoredSimple from "../../static/TwoColoredSimple.svg"
import FiveColord from "../../static/FiveColord.svg"
import FiveSimple from "../../static/fiveSimple.svg"
import MTSimple from "../../static/MTSimple.svg"
import MTColord from "../../static/MTColord.svg"
import NineColord from "../../static/NineColord.svg"
import NineSimple from "../../static/NineSimple.svg"

function OurImpact() {
  const [flagOne, setFlagOne] = useState(false);
  const [flagTwo, setFlagTwo] = useState(false);
  const [flagThree, setFlagThree] = useState(false);
  const [flagFour, setFlagFour] = useState(false);
  return (
    <div className='flex flex-col justify-center items-center align-center w-full mt-[100px] sm:mt-60'>
      <div className='flex align-center items-center'>
        <p className='font-extrabold text-4xl text-black w-full text-center'> IMPACT </p>
        <img className=' ml-8' src={Heart} alt="Heat"></img>
      </div>
      <p className=' text-lg font-normal text-[#757575] mt-8 px-4 sm:px-0 text-center'>Saving the eco-system, All resources at a time</p>
      <div className=' hidden sm:flex mt-24 justify-between align-center items-center space-x-20'>
        <div className='flex flex-col items-center align-center space-y-3 mb-5'>
          <img src={flagOne ? NineColord : NineSimple} alt='twoColored' onMouseEnter={() => setFlagOne(true)} onMouseLeave={() => setFlagOne(false)}></img>
          <p className=' text-base font-semibold'>De-Risked Fisheries</p>
        </div>
        <div className='flex flex-col items-center align-center space-y-3'>
          <img src={flagTwo ? TwoColored : TwoColoredSimple} alt='twoColored' onMouseEnter={() => setFlagTwo(true)} onMouseLeave={() => setFlagTwo(false)}></img>
          <p className=' text-base font-semibold text-center'>ha of natural water-bodies<br></br> under de-risking</p>
        </div>
        <div className='flex flex-col items-center align-center space-y-3'>
          <img src={flagThree ? FiveColord : FiveSimple} alt='twoColored' onMouseEnter={() => setFlagThree(true)} onMouseLeave={() => setFlagThree(false)}></img>
          <p className=' text-base font-semibold text-center'>people provided with<br></br> sustainable healthy protein</p>
        </div>
        <div className='flex flex-col items-center align-center space-y-3 mb-5'>
          <img src={flagFour ? MTColord : MTSimple} alt='twoColored' onMouseEnter={() => setFlagFour(true)} onMouseLeave={() => setFlagFour(false)}></img>
          <p className=' text-base font-semibold'>Freshwater conserved</p>
        </div>
      </div>
      <div className='sm:hidden grid grid-cols-2 gap-8 mt-8 px-4'>
        <div className='flex flex-col items-center align-center space-y-3'>
          <img src={flagOne ? NineColord : NineSimple} alt='twoColored' onMouseEnter={() => setFlagOne(true)} onMouseLeave={() => setFlagOne(false)}></img>
          <p className=' text-sm text-center font-semibold'>De-Risked Fisheries</p>
        </div>
        <div className='flex flex-col items-center align-center space-y-3'>
          <img src={flagTwo ? TwoColored : TwoColoredSimple} alt='twoColored' onMouseEnter={() => setFlagTwo(true)} onMouseLeave={() => setFlagTwo(false)}></img>
          <p className=' text-sm text-center font-semibold'>ha of natural water-bodies under de-risking</p>
        </div>
        <div className='flex flex-col items-center align-center space-y-3'>
          <img src={flagThree ? FiveColord : FiveSimple} alt='twoColored' onMouseEnter={() => setFlagThree(true)} onMouseLeave={() => setFlagThree(false)}></img>
          <p className=' text-sm text-center font-semibold'>people provided with sustainable healthy protein</p>
        </div>
        <div className='flex flex-col items-center align-center space-y-3 mt-5'>
          <img src={flagFour ? MTColord : MTSimple} alt='twoColored' onMouseEnter={() => setFlagFour(true)} onMouseLeave={() => setFlagFour(false)}></img>
          <p className=' text-sm text-center font-semibold'>Freshwater conserved</p>
        </div>
      </div>
    </div>
  )
}

export default OurImpact
