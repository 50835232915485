import React, { useState, useEffect } from 'react'
import Flag from "../../static/flag.svg"
import BackgroundBlob from "../../static/backgroundBlob.svg"
import LineSvg from "../../static/lineSvg.svg"
import Ellipse from "../../static/Ellipse.svg"
import BlueEllipse from "../../static/blueEllipse.svg"
import { isMobile } from "react-device-detect";


function OurJourny() {
    const [index, setIndex] = useState(0);

    const journeyData = [
        [
            'Our journey began, NatureDots',
            'Engaged with 500 fish farmers, 50 women fish farmers',
            'Awarded, Green Growth Startup UNEP Asia-Pacific',
            'Recognized at IIGP 2.0, 3M Young Innovators'
        ],
        [
            'AquaNurch pilots at Chhattisgarh begin',
            'Awarded funding from Biotechnology Industry Research Assistance Council (BIRAC) BIG-16, Department of Biotechnology (DBT), GoI',
            '‘Resilient Region Winner’ and funding by European Institute of Technology (EIT) Climate KIC Global Climate Launchpad',
            'Top 10 global startups at Food-tech Congress, Singapore',
            'FITT-WEE-IIT Delhi Winner'
        ],
        [
            'Product deployment in Chhattisgarh started, along with Haryana and Maharashtra, covering 100 Ha',
            'Selected and received funding as top innovator by Maharashtra State Innovation Society (MSInS) Established under Skills, Employment, Entrepreneurship and Innovation Department, Government of Maharashtra',
            'Supported by RESTOR Foundation estbalished by ETH Zurich, Crother Lab and developed by Google for waterscapes monitoring',
            'Received Technology boosting credits from AWS, Google, Microsoft etc'
        ],
        [
            'NatureDots’ global journey begins',
            'First installation over 2000 ha at Washington State, USA',
            'Awarded at Adaptation and Resilient Acceleration by CDC- Development Finance Institution of the UK government',
            'Awarded as one of the top Innovaiton in water by AMRUT 2.0, Ministry of Housing and Urban Affairs, Government of India',
            'Recognized at Extreme Tech Challenge-TechCrunch Global Start-ups, HolonIQ Asia Top 100 Start-up; and Government of India in top 50 innovations for 75th Year of Independence'
        ],
        [
            'NatureDots’ on runway, and ready to fly!',
            'We are strong, committed Super-team of 15!',
            'IN 2023, AquaNurch to cover 10,000 Ha of Fresh Water Bodies',
            'Developed unique solution for 1000 small scale fish farmers',
            'Free offering to Small to Mid-scale Women Fish Farmers',
            'Awarded as top 10 Innovations, Global Fresh Water by UpLink of World Economic Forum'
        ]

    ];

    return (
        <div className='flex flex-col justify-center items-center align-center w-full mt-[100px] sm:mt-60'>
            <div className='flex align-center items-center'>
                <p className='font-bold text-4xl text-black w-full text-center'> JOURNEY </p>
                <img className=' sm:ml-8' src={Flag} alt="Heat"></img>
            </div>
            <div className='felx flex-col text-center space-y-[32px] my-[32px]'>
                <p className=' text-sm sm:text-lg font-semibold text-[#00208C]'>WE ARE WOLRD’S FIRST ‘WHALE’ START UP</p>
                <p className='hidden sm:block leading-8 text-sm sm:text-lg font-normal'>Achieving a 'Hockey-stick' acceleration for solving hard-problems, fusing profits and <br></br>impact in tandem for creating a better world</p>
                <p className=' sm:hidden mx-4 leading-8 text-sm  font-normal'>Achieving a 'Hockey-stick' acceleration for solving hard-problems, fusing profits and impact in tandem for creating a better world</p>
            </div>
            <div className='flex-col py-4 sm:my-[90px] mx-4 sm:mx-0  px-3 sm:px-6 text-xs sm:text-sm font-normal space-y-2 border border-[#DADADA] rounded-xl sm:w-[480px]'>
                {
                    journeyData[index].map((item, index) => {
                        return <div key={index} className="flex"><li ></li><p className='sm:leading-6'>{item}</p></div>
                    })
                }
            </div>
            <div className=' hidden sm:block mt-10 relative'>
                <img src={LineSvg} alt='LineSvg' />
                <div className='absolute left-[307px] bottom-[-12px] cursor-pointer' onClick={() => setIndex(0)}>
                    <img src={index !== 0 ? Ellipse : BlueEllipse} alt="Ellips" />
                    <p>2019</p>
                </div>
                <div className='absolute left-[500px] bottom-[-25px] cursor-pointer' onClick={() => setIndex(1)}>
                    <img src={index !== 1 ? Ellipse : BlueEllipse} alt="Ellips" />
                    <p>2020</p>
                </div>
                <div className='absolute left-[700px] bottom-[30px] cursor-pointer' onClick={() => setIndex(2)}>
                    <img src={index !== 2 ? Ellipse : BlueEllipse} alt="Ellips" />
                    <p>2021</p>
                </div>
                <div className='absolute left-[900px] bottom-[20px] cursor-pointer' onClick={() => setIndex(3)}>
                    <img src={index !== 3 ? Ellipse : BlueEllipse} alt="Ellips" />
                    <p>2022</p>
                </div>
                <div className='absolute left-[1100px] bottom-[-25px] cursor-pointer' onClick={() => setIndex(4)}>
                    <img src={index !== 4 ? Ellipse : BlueEllipse} alt="Ellips" />
                    <p>2023</p>
                </div>
            </div>
            <div className='sm:hidden mt-10 relative'>
                <img src={LineSvg} alt='LineSvg' />
                <div className='absolute left-[30px] top-[-10px] cursor-pointer' onClick={() => setIndex(0)}>
                    <img src={index !== 0 ? Ellipse : BlueEllipse} alt="Ellips" />
                    <p>2019</p>
                </div>
                <div className='absolute left-[100px] bottom-[-34px] cursor-pointer' onClick={() => setIndex(1)}>
                    <img src={index !== 1 ? Ellipse : BlueEllipse} alt="Ellips" />
                    <p>2020</p>
                </div>
                <div className='absolute left-[175px] bottom-[-20px] cursor-pointer' onClick={() => setIndex(2)}>
                    <img src={index !== 2 ? Ellipse : BlueEllipse} alt="Ellips" />
                    <p>2021</p>
                </div>
                <div className='absolute left-[250px] bottom-[-25px] cursor-pointer' onClick={() => setIndex(3)}>
                    <img src={index !== 3 ? Ellipse : BlueEllipse} alt="Ellips" />
                    <p>2022</p>
                </div>
                <div className='absolute left-[320px] bottom-[-35px] cursor-pointer' onClick={() => setIndex(4)}>
                    <img src={index !== 4 ? Ellipse : BlueEllipse} alt="Ellips" />
                    <p>2023</p>
                </div>
            </div>
        </div>
    )
}

export default OurJourny