import React from 'react'
import Logo from "../../static/NaturedotsLogo.svg"
import LinkedInB from "../../static/linkedInBlack.svg"
import TwitterB from "../../static/twitterBlack.svg"
import FacebookB from "../../static/facebookBlack.svg"
import { AiOutlineInstagram } from "react-icons/ai";
import Insta from "../../static/Insta.svg"
import { useNavigate } from 'react-router-dom'

function CompanySection() {
  const navigate = useNavigate()
  return (
    <div className='flex sm:flex-row flex-col  sm:justify-center align-center mt-[100px] sm:mt-60 py-[50px] sm:space-x-56 border-t-[0.8px] border-t-[#C4C4C4] border-b-[0.8px] border-b-[#C4C4C4] w-full sm:w-fit sm:mx-[100px]'>
      <div>
        <img src={Logo} className="mx-5 sm:mx-0" alt="Logo" />
      </div>
      <div className='sm:hidden flex justify-between mx-10 mt-5 '>

        <div className=' flex flex-col align-center items-start space-y-6'>
          <p className=' font-bold text-lg text-[#757575] cursor-pointer' onClick={() => window.open('https://www.aquanurch.com/overallsummary')} >Company</p>
          <p className=' font-medium text-base text-[#757575] cursor-pointer' onClick={() => navigate('/product')}> AquaNurch </p>
          <p className=' font-medium text-base text-[#757575] cursor-pointer' onClick={() => navigate('/careers')}> Careers </p>
          <p className=' font-medium text-base text-[#757575] cursor-pointer' onClick={() => window.open('https://www.aquanurch.com/overallsummary')}> Get a Demo </p>
        </div>
        <div className=' flex flex-col align-center items-start space-y-6'>
          <p className=' font-bold text-lg text-[#757575]'>Address</p>
          <p className=' font-medium text-base text-[#757575]'> New Delhi, India <br></br>+91 9205409981 </p>
        </div>

      </div>
      <div className=' hidden sm:flex flex-col  items-start space-y-6 w-[140px]'>
        <p className=' font-bold text-lg text-[#757575] cursor-pointer' onClick={() => window.open('https://www.aquanurch.com/overallsummary')} >Company</p>
        <p className=' font-medium text-base text-[#757575] cursor-pointer' onClick={() => navigate('/product')}> AquaNurch </p>
        <p className=' font-medium text-base text-[#757575] cursor-pointer' onClick={() => navigate('/careers')}> Careers </p>
        <p className=' font-medium text-base text-[#757575] cursor-pointer' onClick={() => window.open('https://www.aquanurch.com/overallsummary')}> Get a Demo </p>
      </div>
      <div className=' hidden sm:flex flex-col align-center items-start space-y-6 w-[180px]'>
        <p className=' font-bold text-lg text-[#757575]'>Address</p>
        <p className=' font-medium text-base text-[#757575]'> New Delhi, India <br></br>+91 9205409981 </p>
      </div>
      <div className=' hidden sm:flex flex-col align-center items-start space-y-6'>
        <p className=' font-bold text-lg text-[#757575]'>Stay in touch</p>
        <p className=' font-medium text-base text-[#757575]'> reachus@naturedots.com </p>
        <div className=' flex align-center items-center space-x-4'>
          <img src={LinkedInB} className="cursor-pointer" alt="linedInBlack" onClick={() => window.open('https://www.linkedin.com/company/naturedots/')} />
          <img src={TwitterB} className="cursor-pointer" alt="twitterBlack" onClick={() => window.open('https://twitter.com/naturedots')} />
          <img src={Insta} alt='insta' className='cursor-pointer' onClick={() => window.open('https://www.instagram.com/aquanurch/?hl=en')} />
        </div>
      </div>
    </div>
  )
}

export default CompanySection
