import React from 'react'
import NewsCarousal from './NewsCrousal'

function NewsSection() {
  return (
    <div className='flex flex-col justify-center items-center align-center w-full mt-[100px] sm:mt-60 bg-[#F3F3FB] py-[36px] sm:py-[90px]'>
        <p className=' font-semibold text-lg text-[#00208C]'>IN THE NEWS</p>
        <p className='my-8 text-lg sm:text-4xl font-bold text-center'>We’re changing the game just like you</p>
        <NewsCarousal />
        
    </div>
  )
}

export default NewsSection