import React from 'react';
import { useNavigate } from 'react-router-dom';
import LinkedInB from '../../static/linkedInBlack.svg';
import TwitterB from '../../static/twitterBlack.svg';

import Insta from '../../static/Insta.svg';

function FooterSection() {
	const navigate = useNavigate();
	return (
		<div className='flex w-full sm:justify-start py-[48px] justify-center  sm:px-[100px]'>
			<div className=' hidden sm:flex space-x-[53px] align-center '>
				<p className=' text-base font-medium text-[#757575]'>
					Copyright @2024 Naturedots. All rights reserved.
				</p>
				<p
					className=' text-[#00208C] cursor-pointer'
					onClick={() => navigate('/privacypolicy')}
				>
					Privacy Policy
				</p>
				<p
					className='text-[#00208C] cursor-pointer'
					onClick={() => navigate('/usepolicy')}
				>
					Terms of Use
				</p>
			</div>
			<div className='sm:hidden flex flex-col justify-center items-center space-y-3 '>
				<div className=' flex align-center items-center space-x-4'>
					<img
						src={LinkedInB}
						className='cursor-pointer'
						alt='linedInBlack'
						onClick={() =>
							window.open('https://www.linkedin.com/company/naturedots/')
						}
					/>
					<img
						src={TwitterB}
						className='cursor-pointer'
						alt='twitterBlack'
						onClick={() => window.open('https://twitter.com/naturedots')}
					/>
					<img
						src={Insta}
						alt='insta'
						className='cursor-pointer'
						onClick={() =>
							window.open('https://www.instagram.com/aquanurch/?hl=en')
						}
					/>
				</div>
				<p className=' px-4 text-sm font-medium text-[#757575]'>
					Copyright @2024 Naturedots. All rights reserved.
				</p>
				<div className='flex justify-evenly w-full text-sm sm:text-base'>
					<p
						className=' text-[#00208C] cursor-pointer'
						onClick={() => navigate('/privacypolicy')}
					>
						Privacy Policy
					</p>
					<p
						className=' text-[#00208C] cursor-pointer'
						onClick={() => navigate('/usepolicy')}
					>
						Terms of Use
					</p>
				</div>
			</div>
		</div>
	);
}

export default FooterSection;
