import React, { useEffect } from 'react';
import Navbar from '../components/Navbar/Navbar';
import ProductHeading from '../Pages/ProductPage/ProductHeadingSection/ProductHeading';
import AquanurchImpact from '../Pages/ProductPage/ProductImapctSection/AquanurchImpact';
import AquanurchSystem from '../Pages/ProductPage/AquanurchSystemSection/AquanurchSystem';
import ProductsTab from '../Pages/ProductPage/ProductsTab/ProductsTab';
import BuildingBlocks from '../Pages/ProductPage/BuildingBlocksSection/BuildingBlocks';
import GetInTouch from '../Pages/ProductPage/GetInTouchSection/GetInTouch';
import CompanySection from '../components/CompanySectrion/CompanySection';
import FooterSection from '../components/FooterSection/FooterSection';
import MobileDropdown from '../components/Dropdown/MobileDropdown';
import NewsSection from '../components/NewsSection/NewsSection';
import PricingSection from '../Pages/ProductPage/PricingSection/PricingSection';
import { useLocation } from 'react-router-dom';

function Product({ isOpen, toggleDropdown }) {
	const location = useLocation();
	const { params1 } = location.state || {};
	console.log('ttttt', location.state);
	useEffect(() => {
		if (params1) {
			const element = document.getElementById('getInTouch');
			if (element) {
				// Ensure the element is visible and not restricted by any parent container overflow
				setTimeout(() => {
					element.scrollIntoView({
						behavior: 'smooth',
						block: 'start',
						inline: 'nearest',
					});
				}, 100); // A small delay might help browser rendering and layout recalculation
			}
		}
	}, [params1]);

	return (
		<>
			{!isOpen ? (
				<div className='flex flex-col w-full overflow-x-hidden'>
					<Navbar isOpen={isOpen} toggleDropdown={toggleDropdown} />
					<ProductHeading />
					<AquanurchSystem />
					<BuildingBlocks />
					<ProductsTab />
					<AquanurchImpact />
					<PricingSection />
					<NewsSection />
					<div id='getInTouch'>
						<GetInTouch />
					</div>
					<CompanySection />
					<FooterSection />
				</div>
			) : (
				<MobileDropdown
					toggleDropdown={toggleDropdown}
					className='flex flex-col h-full w-full overflow-x-hidden'
				/>
			)}
		</>
	);
}

export default Product;
