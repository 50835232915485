import React from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import TempImg from '../../../static/TempImg.svg';
import { isMobile } from 'react-device-detect';
import './Carousal.css';

function Carousal() {
	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 2,
		slidesToScroll: 1,
		arrows: isMobile ? false : true,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
					infinite: true,
					dots: true,
					arrows: isMobile ? false : true,
				},
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					initialSlide: 2,
					arrows: isMobile ? false : true,
				},
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					arrows: isMobile ? false : true,
				},
			},
		],
	};
	return (
		<div className='w-full sm:px-28 sm:py-[60px]'>
			<Slider className='sm:pb-8' {...settings}>
				<div className='px-3 cursor-pointer'>
					<iframe
						className='object-cover h-[250px] w-full mt-8 sm:mt-0 sm:w-[570px] rounded-lg sm:h-[430px] cursor-pointer'
						src='https://www.youtube.com/embed/YdSduGEMIPA'
						title='An Innovator Explains: How to build resilient waterscapes'
						frameborder='0'
						allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
						allowfullscreen
					></iframe>
				</div>
				<div className='px-3 cursor-pointer'>
					<iframe
						className='object-cover h-[250px] w-full mt-8 sm:mt-0 sm:w-[570px] rounded-lg sm:h-[430px]'
						src='https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:7044277158583627776?compact=1'
						allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
						frameborder='0'
						allowfullscreen=''
						title='Embedded post'
					></iframe>
				</div>
				<div className='px-3 cursor-pointer'>
					<iframe
						className='object-cover h-[250px] w-full mt-8 sm:mt-0 sm:w-[570px] rounded-lg sm:h-[430px]'
						src='https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:7043970463760281600?compact=1'
						height='399'
						width='710'
						allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
						frameborder='0'
						allowfullscreen=''
						title='Embedded post'
					></iframe>
				</div>
				<div className='px-3'>
					<iframe
						className='object-cover h-[250px] w-full mt-8 sm:mt-0 sm:w-[570px] rounded-lg sm:h-[430px] cursor-pointer'
						src='https://www.youtube.com/embed/6c0J8H4Oezg'
						title='AquaNurch de-risking water ecosystem from ecological and climate risks.'
						frameBorder='0'
						allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
						allowFullScreen
					></iframe>
				</div>
				<div className='px-3'>
					<iframe
						className='object-cover h-[250px] w-full mt-8 sm:mt-0 sm:w-[570px] rounded-lg sm:h-[430px] cursor-pointer'
						src='https://www.youtube.com/embed/FQy4Y6EK4TM'
						title='AquaNurch for Restoration, Protection and Conservation on all Water Ecosystems'
						frameBorder='0'
						allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
						allowFullScreen
					></iframe>
				</div>
				<div className='px-3'>
					<iframe
						className='object-cover h-[250px] w-full mt-8 sm:mt-0 sm:w-[570px] rounded-lg sm:h-[430px] cursor-pointer'
						src='https://www.youtube.com/embed/fQyGHivdFq8'
						title='This start-up protects water security using AI'
						frameborder='0'
						allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
						allowfullscreen
					></iframe>
				</div>
				<div className='px-3'>
					<iframe
						className='object-cover h-[250px] w-full mt-8 sm:mt-0 sm:w-[570px] rounded-lg sm:h-[430px] cursor-pointer'
						src='https://www.youtube.com/embed/FQy4Y6EK4TM'
						title='AquaNurch for Restoration, Protection and Conservation on all Water Ecosystems'
						frameBorder='0'
						allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
						allowFullScreen
					></iframe>
				</div>
			</Slider>
		</div>
	);
}

export default Carousal;
