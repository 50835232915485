import React from 'react';
import Logo from '../../static/NaturedotsLogo.svg';
import { MdClear } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import useAnalyticsEventTracker from '../GAEventFiles/useAnalyticsEventTracker.js';

function MobileDropdown({ toggleDropdown }) {
	const navigate = useNavigate();
	const gaEventTracker = useAnalyticsEventTracker();
	const redirectToProductPage = () => {
		navigate('/product');
		gaEventTracker('Product Page');
		toggleDropdown();
	};
	const redirectToHomePage = () => {
		navigate('/');
		gaEventTracker('Main Page');
		toggleDropdown();
	};
	const redirectToCareersPage = () => {
		navigate('/careers');
		gaEventTracker('Careers Page');
		toggleDropdown();
	};
	const redirectToInsightsPage = () => {
		navigate('/insights');
		gaEventTracker('Insights Page');
		toggleDropdown();
	};
	return (
		<div className='bg-white flex flex-col '>
			<div className='flex justify-between h-16 sm:h-24 px-5 sm:px-28 py-3 items-center shadow-md bg-white'>
				<img className=' cursor-pointer h-[50px]' src={Logo} alt='logoImg' />
				<MdClear onClick={toggleDropdown} />
			</div>
			<div className='flex flex-col mx-auto items-center space-y-[36px] my-[48px] justify-center'>
				<p
					className={`cursor-pointer ${
						window.location.pathname === '/' ? 'text-[#009900]' : 'text-black'
					}`}
					onClick={redirectToHomePage}
				>
					Our Dream
				</p>
				<p
					className={`cursor-pointer ${
						window.location.pathname === '/product'
							? 'text-[#009900]'
							: 'text-black'
					}`}
					onClick={redirectToProductPage}
				>
					Product
				</p>
				<p
					className={`cursor-pointer ${
						window.location.pathname === '/insights'
							? 'text-[#009900]'
							: 'text-black'
					}`}
					onClick={redirectToInsightsPage}
				>
					Insights
				</p>
				<p
					className={`cursor-pointer ${
						window.location.pathname === '/careers'
							? 'text-[#009900]'
							: 'text-black'
					}`}
					onClick={redirectToCareersPage}
				>
					Careers
				</p>
				{/* <p onClick={() => window.open('https://www.aquanurch.com/overallsummary')}>About us</p> */}
				<div
					className=' flex aligne-center justify-center border border-slate-600 px-5 py-3 bg-white rounded-3xl  cursor-pointer'
					onClick={() =>
						window.open('https://www.aquanurch.com/overallsummary')
					}
				>
					<p className='font-medium text-sm'>Login / Signup</p>
				</div>
				<div
					className=' flex aligne-center justify-center border border-slate-600 px-5 py-3 rounded-3xl  bg-[#00208C] cursor-pointer'
					onClick={() =>
						window.open('https://www.aquanurch.com/overallsummary')
					}
				>
					<p className='font-medium text-sm text-white'>Get a Demo {' >>'}</p>
				</div>
			</div>
		</div>
	);
}

export default MobileDropdown;
