import { useEffect } from 'react';
import PolicyHeader from '../Pages/PrivacyPolicyPage/PolicyHeader/PolicyHeader'
import MobileDropdown from '../components/Dropdown/MobileDropdown'
import UsePolicyHeader from '../Pages/UsePolicy/UsePolicyHeader/UsePolicyHeader';




function UsePolicy({ isOpen , toggleDropdown }) {
    useEffect(() => {
        window.scrollTo(0,0)
    },[]);
  return (
    <>
        {!isOpen ? <div className="flex flex-col w-full overflow-x-hidden">
              <UsePolicyHeader />
              
            </div> : <MobileDropdown toggleDropdown={toggleDropdown} className="flex flex-col w-screen h-screen overflow-x-hidden" />}
    </>
  )
}

export default UsePolicy