import React, { useEffect } from 'react';
import MobileDropdown from '../../components/Dropdown/MobileDropdown';
import Navbar from '../../components/Navbar/Navbar';
import FeatureInsightsCard from './FeatureInsightsCard';
import TechnicalPaperSection from './TechnicalPaperSection';
import BlogsSection from './BlogsSection';
import MediaSection from './MediaSection';
import SubscribeNewsletter from './SubscribeNewsLetter';

export default function InsightsPage({ isOpen, toggleDropdown }) {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<>
			{!isOpen ? (
				<div className='flex flex-col w-full overflow-x-hidden'>
					<Navbar isOpen={isOpen} toggleDropdown={toggleDropdown} />
					<div
						onClick={() => window.open('/insights/AQN001')}
						className='cursor-pointer'
					>
						<FeatureInsightsCard />
					</div>
					<MediaSection />
					<SubscribeNewsletter />
				</div>
			) : (
				<MobileDropdown
					toggleDropdown={toggleDropdown}
					className='flex flex-col w-screen h-screen overflow-x-hidden'
				/>
			)}
		</>
	);
}
