import React, { useEffect } from 'react'
import TeamWork from "../../../static/TeamWork.svg"

function CareersHeading() {
    useEffect(() => {
        window.scrollTo(0,0)
    },[]);
    return (
        <div className='w-full mt-20 items-center flex flex-col'>
            <div className='flex flex-col w-full mt-5 sm:mt-14 text-center'>
                <p className=' font-bold text-2xl sm:text-4xl text-black'>Join our growing team!</p>
                <p className=' hidden sm:block font-normal text-lg text-[#757575] mt-6'>We love what we do and we do it with passion for Combining <br></br> Forces of Nature and Technology</p>
            </div>
            <img className='sm:hidden my-[40px] w-[300px]' src={TeamWork} alt="teamWork"/>
            <div className='hidden w-fit mx-auto mt-8 sm:flex aligne-center justify-center border border-slate-600 px-5 py-3 rounded-3xl bg-[#00208C] cursor-pointer' onClick={() => window.open('https://www.linkedin.com/company/naturedots/jobs/')}>
                <p className='font-medium text-sm text-white'>
                    See Current Openings
                </p>
            </div>
            <img className='hidden sm:block my-[60px]' src={TeamWork} alt="teamWork"/>
            <p className='sm:hidden font-normal text-base text-[#757575] text-center mt-6 mx-5 leading-8'>We love what we do and we do it with passion for Combining Forces of Nature and Technology</p>
            <div className='sm:hidden w-fit mx-auto mt-8 flex aligne-center justify-center border border-slate-600 px-[52px] py-5 rounded-[32px] bg-[#00208C] cursor-pointer' onClick={() => window.open('https://www.linkedin.com/company/naturedots/jobs/')}>
                <p className='font-medium text-base text-white'>
                    View Jobs
                </p>
            </div>
        </div>
    )
}

export default CareersHeading