import React from 'react';
import Logo from '../../static/NaturedotsLogo.svg';
import Privacy from '../../static/Privacy.svg';
import { useNavigate } from 'react-router-dom';

function RefundPloicy() {
	const navigate = useNavigate();
	return (
		<div className='flex flex-col'>
			<div className='sm:hidden px-4 flex flex-col space-y-4 mt-4'>
				<p className='text-[#000088] font-bold text-xl'>REFUND POLICY</p>
			</div>
			<div className='h-[250px] w-full bg-[#F3F3FB] hidden sm:block'>
				<div className='flex px-20 py-5'>
					<img
						className=' cursor-pointer h-[50px]'
						src={Logo}
						alt='logoImg'
						onClick={() => navigate('/')}
					/>
					<div className='flex flex-col space-y-4 items-center flex-1'>
						<img
							className=' cursor-pointer h-[149px] w-[200px]'
							src={Privacy}
							alt='logoImg'
						/>
						<p className='text-[#000088] font-bold text-2xl'>REFUND POLICY</p>
					</div>
				</div>
			</div>
			<div className='flex flex-col px-4 sm:px-20 py-5 h-full space-y-8 mt-5'>
				<div className='flex flex-col space-y-2'>
					<p className='text-sm font-normal leading-6 text-black'>
						Thank you for choosing NatureDots! We at NatureDots strive to
						provide our customers with the best solutions and services. Please
						note that NatureDots ordinarily does not provide refund to our
						customers once the subscription is purchased for our solutions and
						services, however under exceptional cases and certain conditions
						detailed below, a refund may be processed. We request our customers
						to read all the terms and conditions pertaining to purchase before
						placing an order with NatureDots.
					</p>
					<p className='text-sm font-normal leading-6 text-black'>
						We request you to please note the following terms and conditions for
						refund-
					</p>
				</div>

				<div className='flex flex-col space-y-4'>
					<li className='text-black text-sm font-normal leading-6'>
						Customers ordinarily shall have no right to seek a refund of the
						amount paid by them towards the mobile application ‘AquaNurch’
						provided by NatureDots.
					</li>
					<li className='text-black text-sm font-normal leading-6'>
						Customers shall not be permitted to downgrade the subscription model
						once the transaction for a particular model has been completed for
						that year or month but can choose to select a new offering once the
						earlier subscription validity is completed or terminated.
					</li>
					<li className='text-black text-sm font-normal leading-6'>
						Customer can upgrade subscription or add new paid features at any
						time, by opting for the upgradation module or addition of new paid
						feature and the overall credit will then reflect in the following
						term.
					</li>
					<li className='text-black text-sm font-normal leading-6'>
						In case a Customer wishes to seek refund due to any technical
						glitch, app malfunction or any other technical problem not caused by
						the Customer himself, he shall send an intimation to{' '}
						<span className='underline text-[#000088]'>
							customer@naturedots.com
						</span>{' '}
						with all supporting documents and proofs.
					</li>
					<li className='text-black text-sm font-normal leading-6'>
						Customer shall send all the details of the purchase of subscription
						such as order number, transaction date and amount, device
						configuration, brief description of the problem faced and any
						supporting documents or screenshots along with the intimation while
						requesting for refund or raising the issue.
					</li>
					<li className='text-black text-sm font-normal leading-6'>
						NatureDots shall look into the issue raised by the Customer and
						conduct verification of the issue faced by the Customer. NatureDots
						may also ask the Customer to provide any further information
						regarding the issue faced by him.
					</li>
					<li className='text-black text-sm font-normal leading-6'>
						Only in cases where the issue raised by the Customer is not fixable
						by NatureDots after conducting verification, the Customer be given a
						refund of the amount paid towards the subscription.
					</li>
					<li className='text-black text-sm font-normal leading-6'>
						NatureDots shall aim to process permissible refunds within a period
						of thirty (30) business days. It is clarified that NatureDots
						reserves the right to reject refund request of the Customer (either
						partially or completely).
					</li>
					<li className='text-black text-sm font-normal leading-6'>
						In case an issue arises with the AquaNurch app under regular
						scheduled maintenance, no refund shall be applicable.
					</li>
					<div className='flex flex-col space-y-1'>
						<p className='text-black text-base font-bold underline'>
							Grievance redressal
						</p>
						<li className='text-black text-sm font-normal leading-6'>
							In case of any grievance, the Customer can contact team NatureDots
							at{' '}
							<span className='underline text-[#000088]'>
								customer@naturedots.com
							</span>{' '}
							NatureDots will try to respond to the grievance with in 15 days.
						</li>
					</div>
				</div>
			</div>

			<p
				className='text-[#00208C] hidden sm:block font-bold text-2xl w-full text-center py-8 cursor-pointer'
				onClick={() => navigate('/usepolicy')}
			>
				Read Terms of Use Policy
			</p>
			<div className='flex items-center w-full justify-center py-8 space-x-8'>
				<div
					className='px-[28px] py-3 flex items-center justify-center rounded-3xl text-black text-sm border border-[#00208C] cursor-pointer'
					onClick={() => navigate('/')}
				>
					Close
				</div>
				<div
					className='px-[28px] py-3 flex items-center justify-center rounded-3xl text-white text-sm border bg-[#00208C] cursor-pointer'
					onClick={() => navigate('/')}
				>
					Accept
				</div>
			</div>
		</div>
	);
}

export default RefundPloicy;
