import React, { useEffect } from 'react'
import Navbar from '../components/Navbar/Navbar'
import CareersHeading from '../Pages/CareersPage/CareersHeadingSection/CareersHeading'
import WorkCulture from '../Pages/CareersPage/WorkCulture/WorkCulture'
import TeamLocation from '../Pages/CareersPage/TeamLocationSection/TeamLocation'
import CareersSection from '../Pages/CareersPage/CareerSection/CareersSection'
import GetInTouch from '../Pages/ProductPage/GetInTouchSection/GetInTouch'
import CompanySection from '../components/CompanySectrion/CompanySection'
import FooterSection from '../components/FooterSection/FooterSection'
import MobileDropdown from '../components/Dropdown/MobileDropdown'


function Career( { isOpen, toggleDropdown } ) {
  useEffect(() => {
    window.scrollTo(0,0)
},[]);
  return (
    <>
        {!isOpen ? <div className="flex flex-col w-full overflow-x-hidden">
              <Navbar isOpen={isOpen} toggleDropdown={toggleDropdown} />
              <CareersHeading />
              <WorkCulture />
              <TeamLocation />
              <CareersSection />
              <GetInTouch />
              <CompanySection />
              <FooterSection />
            </div> : <MobileDropdown toggleDropdown={toggleDropdown} className="flex flex-col h-full w-full overflow-x-hidden" />}
    </>
  )
}

export default Career