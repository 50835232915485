import React, { useState } from 'react';
import VectorC from '../../../static/VectorC.svg';
import ProFirst from '../../../static/ProFirst.svg';
import ProTwo from '../../../static/ProTwo.svg';
import ProThree from '../../../static/ProThree.svg';
import ProFour from '../../../static/ProFour.svg';
import ProFive from '../../../static/ProFive.svg';
import ProSix from '../../../static/ProSix.svg';
import ProFreshOne from '../../../static/ProFreshOne.png';
import ProFreshTwo from '../../../static/ProFreshTwo.png';
import ProFreshThree from '../../../static/ProFreshThree.png';
import ProFreshFour from '../../../static/ProFreshFour.png';
import ProFreshFive from '../../../static/ProFreshFive.png';
import ProFreshSix from '../../../static/ProFreshSix.png';
import Rectangle from '../../../static/Rectangle.svg';
import './ProductTab.css';
import ProMobileOne from '../../../static/ProMobileOne.svg';
import ProMobileTwo from '../../../static/ProMobileTwo.svg';
import ProMobileThree from '../../../static/ProMobileThree.svg';
import ProMobileFour from '../../../static/ProMobileFour.svg';
import ProMobileFive from '../../../static/ProMobileFive.svg';
import ProMobileSix from '../../../static/ProMobileSix.svg';

import ProMobileFreshOne from '../../../static/ProMobileFreshOne.png';
import ProMobileFreshTwo from '../../../static/ProMobileFreshTwo.png';
import ProMobileFreshThree from '../../../static/ProMobileFreshThree.png';
import ProMobileFreshFour from '../../../static/ProMobileFreshFour.png';
import ProMobileFreshFive from '../../../static/ProMobileFreshFive.png';
import ProMobileFreshSix from '../../../static/ProMobileFreshSix.png';
import BlankPcForGifThree from '../../../static/BlankPcForGif.png';
import TabThreeGif from '../../../static/TabThreeGif.gif';
import { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import ComingSoonAnime from './ComingSoonAnime';
import CustomerSupport from './CustomerSupportAnime';
import { Player } from '@lottiefiles/react-lottie-player';
import BlankBlobLaptop from '../../../static/BlankBlobLaptop.png';
import FullBlankBlobLaptop from '../../../static/FullBlankBlobLaptop.png';
import AquanurchLogo from '../../../static/AquaNurchLogo.svg';

function ProductsTab() {
	const [tabIndex, setTabIndex] = useState(0);
	const [readMore, setReadMore] = useState(false);
	const [moblieReadOne, setMobileReadOne] = useState(false);
	const [moblieReadTwo, setMobileReadTwo] = useState(false);
	const [moblieReadThree, setMobileReadThree] = useState(false);
	const [moblieReadFour, setMobileReadFour] = useState(false);
	const [moblieReadFive, setMobileReadFive] = useState(false);
	const [moblieReadSix, setMobileReadSix] = useState(false);
	const [moblieFreshReadOne, setMobileFreshReadOne] = useState(false);
	const [moblieFreshReadTwo, setMobileFreshReadTwo] = useState(false);
	const [moblieFreshReadThree, setMobileFreshReadThree] = useState(false);
	const [moblieFreshReadFour, setMobileFreshReadFour] = useState(false);
	const [moblieFreshReadFive, setMobileFreshReadFive] = useState(false);
	const [moblieFreshReadSix, setMobileFreshReadSix] = useState(false);
	const [stickyClass, setStickyClass] = useState('relative');

	const stickNavbar = () => {
		if (window !== undefined) {
			let windowHeight = window.scrollY;
			console.log(windowHeight);
			if (!isMobile) {
				windowHeight > 2580 && windowHeight < 5460
					? setStickyClass('fixed top-[3rem] sm:pt-[28px] z-50')
					: setStickyClass('relative');
			} else {
				windowHeight > 1742 && windowHeight < 3375
					? setStickyClass('fixed top-[1.25rem] z-50')
					: setStickyClass('relative');
			}
		}
	};

	useEffect(() => {
		window.addEventListener('scroll', stickNavbar);

		return () => {
			window.removeEventListener('scroll', stickNavbar);
		};
	}, []);

	return (
		<div className='flex flex-col'>
			<div className=' flex flex-col justify-center items-center align-center w-full mt-[40px] sm:mt-60'>
				<div className='relative flex flex-col justify-center items-center align-center w-full bg-white sm:bg-[#F7F6F7] pt-[60px]'>
					<p className=' text-center font-bold text-2xl sm:text-4xl text-black'>
						MULTIPLE USE CASES. ONE PLATFORM
					</p>
					<p className='font-semibold text-lg mt-[36px] text-[#00208C]'>
						OUR USE CASES
					</p>
					<div
						className={`max-w-full sm:w-full inset-x-0 mt-[40px] text-base sm:text-2xl flex sm:text-center items-center space-x-[30px] sm:space-x-[60px] sm:justify-center bg-[#F7F6F7] overflow-x-scroll px-11 pt-5 sm:py-0 productScrollbar ${stickyClass}`}
					>
						<p
							className={`pb-5 shrink-0 cursor-pointer ${
								tabIndex === 0
									? 'border-b-4 font-bold  border-[#009900] text-black'
									: 'font-semibold text-[#757575]'
							}`}
							onClick={() => setTabIndex(0)}
						>
							Aquaculture
						</p>
						<p
							className={`pb-5 shrink-0 cursor-pointer ${
								tabIndex === 1
									? 'border-b-4 font-bold  border-[#009900] text-black'
									: 'font-semibold text-[#757575]'
							}`}
							onClick={() => setTabIndex(1)}
						>
							Freshwater
						</p>
						<p
							className={`pb-5 shrink-0 cursor-pointer ${
								tabIndex === 2
									? 'border-b-4 font-bold  border-[#009900] text-black'
									: 'font-semibold text-[#757575]'
							}`}
							onClick={() => setTabIndex(2)}
						>
							Coastal & Marine
						</p>
						<p
							className={`pb-5 shrink-0 cursor-pointer ${
								tabIndex === 3
									? 'border-b-4 font-bold  border-[#009900] text-black'
									: 'font-semibold text-[#757575]'
							}`}
							onClick={() => setTabIndex(3)}
						>
							Blue Carbon
						</p>
						<p
							className={`pb-5 shrink-0 cursor-pointer ${
								tabIndex === 4
									? 'border-b-4 font-bold  border-[#009900] text-black'
									: 'font-semibold text-[#757575]'
							}`}
							onClick={() => setTabIndex(4)}
						>
							Industrial
						</p>
					</div>
				</div>
				{tabIndex === 0 && (
					<div className='leading-8 px-4 sm:px-0 sm:w-[1150px] my-[36px] text-center font-normal text-lg text-[#757575]'>
						<p>
							Our hard-tech product AquaNurch® combines the power of
							‘Nature-based+DeepTech’ for delivering end-to-end aquaculture
							solutions in emerging economies towards instituting climate
							resilient sustainable fisheries. Ensuring high revenue with ease
							to aqua farmers and healthy protein for all.
						</p>
						<p className='cursor-pointer' onClick={() => setReadMore(true)}>
							{!readMore
								? 'read more...'
								: 'AquaNurch is an end-to-end solution suite for aquapreneurs, fish-farmers to de-risk and strengthen their production systems. AquaNurch hardware is designed for small to industrial scale Aquaculture system needs, and is your one-stop-shop solution. AquaNurch System can be customised to address needs of new, commercial, and small scale fish farmers'}
						</p>
					</div>
				)}
				{tabIndex === 1 && (
					<div className='leading-8 px-4 sm:px-0 sm:w-[1150px] my-[36px] text-center font-normal text-lg text-[#757575]'>
						<p>
							AquaNurch system for water assets acts as a pulse checker to help
							bridge key data and information on the water health and
							monitoring. Ensuring resilience from natural and man-made
							disturbances now, and de-risk from climate and ecological risks
							for the future. AquaNurch units acts as a Neural Node, capturing
							the pulse of water ecosystems from the depth and surface of water,
							combining it with the geospatial planetary spatial temporal data,
							processed using proprietary AI models.
						</p>
					</div>
				)}
				{tabIndex === 0 ? (
					<div className='hidden sm:flex flex-col justify-center items-center align-center w-full'>
						<div className='flex items-center my-[48px]'>
							<img src={ProFirst} alt='vectorC' />
							<div className='flex flex-col justify-start ml-[65px]'>
								<p className='font-semibold text-2xl text-black border-b border-black pb-4'>
									All Weather AI-IoT Device for Water Pulse-checker
								</p>
								<p className='text-sm font-normal font-[#757575] my-4 text-[#757575]'>
									Plug-and-play, Industry compatible, water-proof and corrosion
									resistive<br></br> highly protective gear, 4G/Wifi, portfolio
									of 25 sensors. Our experts will <br></br>provide the best
									solution at the least possible cost.
								</p>
							</div>
						</div>

						<div className='flex my-[48px] items-center'>
							<div className='flex flex-col text-right items-end'>
								<p className='font-semibold text-2xl text-black'>
									Real-time Monitoring<br></br> No Frequent Calibration,
									Certified & Tested
								</p>
								<p className='text-sm font-normal font-[#757575] border-t border-black my-4 pt-4 text-[#757575]'>
									AquaNurch systems hardware are designed for a hassle free
									experience, it met all<br></br> required certifications and
									tested over a varied range of ecosystems and weather
								</p>
							</div>
							<img className='ml-[65px]' src={ProTwo} alt='vectorC' />
						</div>

						<div className='flex items-center my-[48px]'>
							<img src={ProThree} alt='vectorC' />
							<div className='flex flex-col justify-start ml-[65px]'>
								<p className='font-semibold text-2xl text-black border-b border-black pb-4'>
									24x7 Customized Trigger-based Alerts and Advisories
								</p>
								<p className='text-sm font-normal font-[#757575] my-4 text-[#757575]'>
									Whether you have an RAS, BioFloc or open-pond system of
									aquaculture or <br></br>reservoir based fisheries, we deliver
									customized insights for your<br></br> production system in 13
									languages
								</p>
							</div>
						</div>

						<div className='flex my-[48px] items-center'>
							<div className='flex flex-col text-right items-end'>
								<p className='font-semibold text-2xl text-black'>
									AI-powered Fish Doctor Assistance
								</p>
								<p className='text-sm font-normal font-[#757575] border-t border-black my-4 pt-4 text-[#757575]'>
									With AquaNurch you just need to click a picture of your fish
									to learn about its health!<br></br> Our expert Fish doctor
									helps to de-risk your farms and fishes. Reach-out to learn
									more
								</p>
							</div>
							<img className='ml-[65px]' src={ProFour} alt='vectorC' />
						</div>

						<div className='flex items-center my-[48px]'>
							<img src={ProFive} alt='vectorC' />
							<div className='flex flex-col justify-start ml-[65px]'>
								<p className='font-semibold text-2xl text-black border-b border-black pb-4'>
									Inclusive People First Design
								</p>
								<p className='text-sm font-normal font-[#757575] my-4 text-[#757575]'>
									AquaNurch system is co-created with fish-farmers and
									aqua-managers, <br></br>ensuring that the user needs are at
									the core of our solution. Ensuring <br></br>inclusivity with
									human-centred design for easy usage and adoption.
								</p>
							</div>
						</div>

						<div className='flex my-[48px] items-center'>
							<div className='flex flex-col text-right items-end'>
								<p className='font-semibold text-2xl text-black'>
									All in one Summary Dashboard!<br></br> Mobile & Web based, in
									13 languages
								</p>
								<p className='text-sm font-normal font-[#757575] border-t border-black my-4 pt-4 text-[#757575]'>
									Access all the information of your production system and
									operations on one,<br></br> decluttered, easy to use dashboard
									screen.
								</p>
							</div>
							<img className='ml-[65px]' src={ProSix} alt='vectorC' />
						</div>
					</div>
				) : tabIndex === 1 ? (
					<div className='hidden sm:flex flex-col justify-center items-center align-center w-full'>
						<div className='flex items-center my-[48px]'>
							<img src={ProFreshOne} alt='vectorC' />
							<div className='flex flex-col justify-start ml-[65px]'>
								<p className='font-semibold text-2xl text-black border-b border-black pb-4'>
									All Weather AquaNurch Device for Water Pulse-checker
								</p>
								<p className='text-sm font-normal font-[#757575] my-4 text-[#757575]'>
									Plug-and-play, Industry compatible, water-proof and corrosion
									resistive<br></br> highly protective gear,
									4G/Wifi/NB-IoT/LoRa, portfolio of 30 sensors,<br></br> fully
									customisable. Our experts will provide the best solution at
									the<br></br> least possible cost, Globally!
								</p>
							</div>
						</div>

						<div className='flex my-[48px] items-center'>
							<div className='flex flex-col text-right items-end'>
								<p className='font-semibold text-2xl text-black'>
									Real-time Monitoring<br></br> No Frequent Calibration,
									Certified & Tested
								</p>
								<p className='text-sm font-normal font-[#757575] border-t border-black my-4 pt-4 text-[#757575]'>
									AquaNurch systems hardware is designed for deployment
									anywhere, which<br></br> AquaNurch AI would also minimise
									hardware and sensors requirement in<br></br> maximum cases,
									whether it’s in a deep-forest stream or middle of a riverine
									island<br></br> or your local water-body
								</p>
							</div>
							<img className='ml-[65px]' src={ProFreshTwo} alt='vectorC' />
						</div>

						<div className='flex items-center my-[48px]'>
							{/* <img src={ProFreshThree} alt='vectorC' /> */}
							<div className='relative'>
								<img
									src={BlankPcForGifThree}
									alt='vectorC'
									className='h-[360px] w-[360px]'
								/>
								<img
									src={TabThreeGif}
									className='h-[182px] w-[285px] top-[83px] left-[38px] absolute rounded-8'
								/>
								<div className='absolute h-7 w-20 bg-white top-[92px] left-[48px] flex items-center justify-center'>
									<img
										src={AquanurchLogo}
										alt='AquanurchLogo'
										className='w-16'
									/>
								</div>
							</div>
							<div className='flex flex-col justify-start ml-[65px]'>
								<p className='font-semibold text-2xl text-black border-b border-black pb-4'>
									De-risking, Climate-resilient and Nature-based solutions
								</p>
								<p className='text-sm font-normal font-[#757575] my-4 text-[#757575]'>
									AquaNurch AI engine uses ensembled models combining
									high-frequency and<br></br> high-quality water, ecological,
									climate, weather and landscape data, to de-risk and<br></br>{' '}
									estimate, suggest and establish climate-resilient waterscapes 
								</p>
							</div>
						</div>

						<div className='flex my-[48px] items-center'>
							<div className='flex flex-col text-right items-end'>
								<p className='font-semibold text-2xl text-black'>
									24x7 Customized Trigger-based<br></br> Alerts and Advisories
								</p>
								<p className='text-sm font-normal font-[#757575] border-t border-black my-4 pt-4 text-[#757575]'>
									When it comes to water, it’s not a straight line but a
									meandering course of<br></br> analysis. Based on the needs of
									the water ecosystem and water manager,<br></br> we generate
									customized observing processes using our AI.{' '}
								</p>
							</div>
							<div className='relative'>
								<img
									className='ml-[65px] w-[365px] h-[363px]'
									src={BlankBlobLaptop}
									alt='vectorC'
								/>
								<div className=' absolute bh-white top-[90px] left-[135px] '>
									<Player
										autoplay
										speed={1}
										loop
										src='https://assets10.lottiefiles.com/private_files/lf30_3lflolyo.json'
										className='h-[170px] w-[170px]'
									></Player>
								</div>
							</div>
						</div>

						<div className='flex items-center my-[48px]'>
							<div className='relative'>
								<img src={ProFreshFive} alt='vectorC' />
								<div className='absolute top-[79px] left-[87px]'>
									<Player
										autoplay
										speed={2}
										loop
										src='https://assets6.lottiefiles.com/packages/lf20_su8vw1n6.json'
										className='h-[200px] w-[200px]'
									></Player>
								</div>
							</div>
							<div className='flex flex-col justify-start ml-[65px]'>
								<p className='font-semibold text-2xl text-black border-b border-black pb-4'>
									Inclusive People First Design
								</p>
								<p className='text-sm font-normal font-[#757575] my-4 text-[#757575]'>
									AquaNurch system is co-created with fish-farmers and
									aqua-managers, <br></br>ensuring that the user needs are at
									the core of our solution. Ensuring <br></br>inclusivity with
									human-centred design for easy usage and adoption.
								</p>
							</div>
						</div>

						<div className='flex my-[48px] items-center'>
							<div className='flex flex-col text-right items-end'>
								<p className='font-semibold text-2xl text-black'>
									All in one Summary Dashboard!<br></br> We customise according
									to your needs
								</p>
								<p className='text-sm font-normal font-[#757575] border-t border-black my-4 pt-4 text-[#757575]'>
									Access all the information of your production system and
									operations on one,<br></br> decluttered, easy to use dashboard
									screen.
								</p>
							</div>
							<div className='relative'>
								<img
									className='ml-[65px] w-[365px] h-[363px]'
									src={FullBlankBlobLaptop}
									alt='vectorC'
								/>
								<div className=' absolute top-[82px] left-[152px]'>
									<Player
										autoplay
										speed={2}
										loop
										src='https://assets7.lottiefiles.com/packages/lf20_n5hsffgz.json'
										className='h-[220px] w-[220px]'
									></Player>
								</div>
							</div>
						</div>
					</div>
				) : (
					<div className='flex flex-col justify-center items-center align-center w-full my-[20px]'>
						{/* <img src={Rectangle} alt="ractangle" /> */}
						<ComingSoonAnime />
						<p className='text-3xl font-normal'>Coming Soon....</p>
					</div>
				)}

				{tabIndex === 0 ||
					(tabIndex === 1 && (
						<div
							className='hidden sm:block text-white w-fit px-5 py-3 bg-[#00208C] rounded-3xl cursor-pointer'
							onClick={() =>
								window.open('https://www.aquanurch.com/overallsummary')
							}
						>
							<p>Get a Demo {'>>'}</p>
						</div>
					))}

				{tabIndex === 0 && (
					<div className='grid grid-cols-2 gap-3 px-4 sm:hidden'>
						<div className='flex flex-col items-center text-center space-y-2'>
							<img src={ProMobileOne} alt='proMobileOne' />
							<p className='text-cetner font-semibold text-sm border-b pb-3 border-[black] leading-5'>
								All Weather AI-IoT Device for Water Pulse-checker
							</p>
							<p className='text-cetner text-xs font-normal leading-[160%] text-[#757575]'>
								Plug-and-play, Industry compatible, water-proof and corrosion
								resistive highly protective gear
							</p>
							{moblieReadOne ? (
								<p className='text-cetner text-xs font-normal text-[#757575] leading-[160%]'>
									, 4G/Wifi, portfolio of 25 sensors. Our experts will provide
									the best solution at the least possible cost.
								</p>
							) : (
								<p
									className='text-[blue] text-xs'
									onClick={() => setMobileReadOne(true)}
								>
									Read More...
								</p>
							)}
						</div>

						<div className='flex flex-col items-center text-center space-y-2'>
							<img src={ProMobileTwo} alt='proMobileOne' />
							<p className='text-cetner font-semibold text-sm border-b pb-3 border-[black] leading-5'>
								Real-time Monitoring No Frequent Calibration
							</p>
							<p className='text-cetner text-xs font-normal text-[#757575] leading-[160%]'>
								AquaNurch systems hardware are designed for a hassle free
								experience, it met all required certifications and tested
							</p>
							{moblieReadTwo ? (
								<p className='text-cetner text-xs font-normal text-[#757575] leading-[160%]'>
									over a varied range of ecosystems and weather.
								</p>
							) : (
								<p
									className='text-[blue] text-xs'
									onClick={() => setMobileReadTwo(true)}
								>
									Read More...
								</p>
							)}
						</div>

						<div className='flex flex-col items-center text-center space-y-2'>
							<img src={ProMobileThree} alt='proMobileOne' />
							<p className='text-cetner font-semibold text-sm border-b pb-3 border-[black] leading-5'>
								24x7 Customized Trigger-based Alerts and Advisories
							</p>
							<p className='text-cetner text-xs font-normal text-[#757575] leading-[160%]'>
								Whether you have an RAS, BioFloc or open-pond system of
								aquaculture or reservoir based fisheries, we deliver customized
							</p>
							{moblieReadThree ? (
								<p className='text-cetner text-xs font-normal text-[#757575] leading-[160%]'>
									insights for your production system in 13 languages
								</p>
							) : (
								<p
									className='text-[blue] text-xs'
									onClick={() => setMobileReadThree(true)}
								>
									Read More...
								</p>
							)}
						</div>

						<div className='flex flex-col items-center text-center space-y-2'>
							<img
								className='h-[172px] mt-5'
								src={ProMobileFour}
								alt='proMobileOne'
							/>
							<p className='text-cetner font-semibold text-sm border-b pb-3 border-[black] leading-5'>
								AI-powered Fish Doctor Assistance
							</p>
							<p className='text-cetner text-xs font-normal text-[#757575] leading-[160%]'>
								With AquaNurch you just need to click a picture of your fish to
								learn about its health! Our expert Fish doctor helps
							</p>
							{moblieReadFour ? (
								<p className='text-cetner text-xs font-normal text-[#757575] leading-[160%]'>
									de-risk your farms and fishes. Reach-out to learn more.
								</p>
							) : (
								<p
									className='text-[blue] text-xs'
									onClick={() => setMobileReadFour(true)}
								>
									Read More...
								</p>
							)}
						</div>

						<div className='flex flex-col items-center text-center space-y-2 mt-5'>
							<img src={ProMobileFive} alt='proMobileOne' />
							<p className='text-cetner font-semibold text-sm border-b pb-3 border-[black] leading-5'>
								Inclusive People First Design
							</p>
							<p className='text-cetner text-xs font-normal text-[#757575] leading-[160%]'>
								AquaNurch system is co-created with fish-farmers and
								aqua-managers, ensuring that the user needs are at the core
							</p>
							{moblieReadFive ? (
								<p className='text-cetner text-xs font-normal text-[#757575] leading-[160%]'>
									of our solution. Ensuring inclusivity with human-centred
									design for easy usage and adoption.
								</p>
							) : (
								<p
									className='text-[blue] text-xs'
									onClick={() => setMobileReadFive(true)}
								>
									Read More...
								</p>
							)}
						</div>

						<div className='flex flex-col items-center text-center space-y-2 mt-6'>
							<img src={ProMobileSix} alt='proMobileOne' />
							<p className='text-cetner font-semibold text-sm border-b pb-3 border-[black] leading-5'>
								{' '}
								Mobile & Web based, in 13 languages
							</p>
							<p className='text-cetner text-xs font-normal text-[#757575] leading-[160%]'>
								Access all the information of your production system and
								operations on one, decluttered, easy to use dashboard screen.
							</p>
							{/* {moblieReadSix  ? <p>, 4G/Wifi, portfolio of 25 sensors. Our experts will provide the best solution at the least possible cost.</p> : <p onClick={() => setMobileReadSix(true)}>Read More...</p>} */}
						</div>
					</div>
				)}

				{tabIndex === 1 && (
					<div className='grid grid-cols-2 gap-3 px-4 sm:hidden'>
						<div className='flex flex-col items-center text-center space-y-2'>
							<img src={ProMobileFreshOne} alt='proMobileOne' />
							<p className='text-cetner font-semibold text-sm border-b pb-3 border-[black] leading-5'>
								All Weather AI-IoT Device for Water Pulse-checker
							</p>
							<p className='text-cetner text-xs font-normal text-[#757575] leading-[160%]'>
								Plug-and-play, Industry compatible, water-proof and corrosion
								resistive highly protective gear
							</p>
							{moblieFreshReadOne ? (
								<p className='text-cetner text-xs font-normal text-[#757575] leading-[160%]'>
									, 4G/Wifi, portfolio of 25 sensors. Our experts will provide
									the best solution at the least possible cost.
								</p>
							) : (
								<p
									className='text-[blue] text-xs'
									onClick={() => setMobileFreshReadOne(true)}
								>
									Read More...
								</p>
							)}
						</div>

						<div className='flex flex-col items-center text-center space-y-2'>
							<img src={ProMobileFreshTwo} alt='proMobileOne' />
							<p className='text-cetner font-semibold text-sm border-b pb-3 border-[black] leading-5'>
								Real-time Monitoring No Frequent Calibration
							</p>
							<p className='text-cetner text-xs font-normal text-[#757575] leading-[160%]'>
								AquaNurch systems hardware are designed for a hassle free
								experience, it met all required certifications and tested
							</p>
							{moblieFreshReadTwo ? (
								<p className='text-cetner text-xs font-normal text-[#757575] leading-[160%]'>
									over a varied range of ecosystems and weather.
								</p>
							) : (
								<p
									className='text-[blue] text-xs'
									onClick={() => setMobileFreshReadTwo(true)}
								>
									Read More...
								</p>
							)}
						</div>

						<div className='flex flex-col items-center text-center space-y-2'>
							<div className='relative'>
								<img
									src={BlankPcForGifThree}
									alt='proMobileOne'
									className='mt-5 w-[140px] h-[134px]'
								/>
								<img
									src={TabThreeGif}
									className='h-[66px] w-[106px] top-[51px] left-[17px] absolute rounded-8'
								/>
								<div className='absolute h-2 w-7 bg-white top-[55px] left-[21px] flex items-center justify-center'>
									<img
										src={AquanurchLogo}
										alt='AquanurchLogo'
										className='w-8'
									/>
								</div>
							</div>

							<p className='text-cetner font-semibold text-sm border-b pb-3 border-[black] leading-5'>
								De-risking, Climate-resilient and Nature-based solutions
							</p>
							<p className='text-cetner text-xs font-normal text-[#757575] leading-[160%]'>
								AquaNurch AI engine uses ensembled models combining
								high-frequency and high-quality water, ecological..
							</p>
							{moblieFreshReadThree ? (
								<p className='text-cetner text-xs font-normal text-[#757575] leading-[160%]'>
									climate, weather and landscape data, to de-risk and estimate,
									suggest and establish climate-resilient waterscapes{' '}
								</p>
							) : (
								<p
									className='text-[blue] text-xs'
									onClick={() => setMobileFreshReadThree(true)}
								>
									Read More...
								</p>
							)}
						</div>

						<div className='flex flex-col items-center text-center space-y-2'>
							<div className='relative'>
								<img
									className='w-[135px] h-[136px] mt-5'
									src={BlankBlobLaptop}
									alt='proMobileOne'
								/>
								<div className=' absolute bh-white top-[50px] left-[21px] '>
									<Player
										autoplay
										speed={1}
										loop
										src='https://assets10.lottiefiles.com/private_files/lf30_3lflolyo.json'
										className='h-[70px] w-[70px]'
									></Player>
								</div>
							</div>
							<p className='text-cetner font-semibold text-sm border-b pb-3 border-[black] leading-5'>
								24x7 Customized Trigger-based Alerts and Advisories
							</p>
							<p className='text-cetner text-xs font-normal text-[#757575] leading-[160%]'>
								With AquaNurch you just need to click a picture of your fish to
								learn about its health! Our expert Fish doctor helps
							</p>
							{moblieFreshReadFour ? (
								<p className='text-cetner text-xs font-normal text-[#757575] leading-[160%]'>
									de-risk your farms and fishes. Reach-out to learn more.
								</p>
							) : (
								<p
									className='text-[blue] text-xs'
									onClick={() => setMobileFreshReadFour(true)}
								>
									Read More...
								</p>
							)}
						</div>

						<div className='flex flex-col items-center text-center space-y-2 mt-5'>
							<div className='relative'>
								<img
									className='w-[135px] h-[136px]'
									src={FullBlankBlobLaptop}
									alt='proMobileOne'
								/>
								<div className=' absolute bh-white top-[32px] left-[36px] '>
									<Player
										autoplay
										speed={2}
										loop
										src='https://assets6.lottiefiles.com/packages/lf20_su8vw1n6.json'
										className='h-[70px] w-[70px]'
									></Player>
								</div>
							</div>
							<p className='text-cetner font-semibold text-sm border-b pb-3 border-[black] leading-5'>
								Inclusive People First Design
							</p>
							<p className='text-cetner text-xs font-normal text-[#757575] leading-[160%]'>
								AquaNurch system is co-created with fish-farmers and
								aqua-managers, ensuring that the user needs are at the core
							</p>
							{moblieFreshReadFive ? (
								<p className='text-cetner text-xs font-normal text-[#757575] leading-[160%]'>
									of our solution. Ensuring inclusivity with human-centred
									design for easy usage and adoption.
								</p>
							) : (
								<p
									className='text-[blue] text-xs'
									onClick={() => setMobileFreshReadFive(true)}
								>
									Read More...
								</p>
							)}
						</div>

						<div className='flex flex-col items-center text-center space-y-2 mt-6'>
							<div className='relative'>
								<img
									className='w-[135px] h-[136px]'
									src={FullBlankBlobLaptop}
									alt='proMobileOne'
								/>
								<div className=' absolute bh-white top-[36px] left-[25px] '>
									<Player
										autoplay
										speed={2}
										loop
										src='https://assets7.lottiefiles.com/packages/lf20_n5hsffgz.json'
										className='h-[70px] w-[90px]'
									></Player>
								</div>
							</div>
							{/* <img src={ProMobileFreshSix} alt='proMobileOne' /> */}
							<p className='text-cetner font-semibold text-sm border-b pb-3 border-[black] leading-5'>
								{' '}
								All in one Summary Dashboard!
							</p>
							<p className='text-cetner text-xs font-normal text-[#757575] leading-[160%]'>
								Access all the information of your production system and
								operations on one, decluttered, easy to use dashboard screen.
							</p>
							{/* {moblieReadSix  ? <p>, 4G/Wifi, portfolio of 25 sensors. Our experts will provide the best solution at the least possible cost.</p> : <p onClick={() => setMobileReadSix(true)}>Read More...</p>} */}
						</div>
					</div>
				)}
			</div>
		</div>
	);
}

export default ProductsTab;
