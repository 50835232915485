import React from 'react'
import BuildingBlocksMobile from "../../../static/BuildingBlocksMobile.svg"
import BuildOne from "../../../static/BuildOne.svg";
import BuildTwo from "../../../static/BuildTwo.svg";
import BuildThree from "../../../static/BuildThree.svg";
import BuildFour from "../../../static/BuildFour.svg";
import BuildFive from "../../../static/BuildFive.svg";
import BuildSix from "../../../static/BuildSix.svg";
import BuildSeven from "../../../static/BuildSeven.svg";
import BuildingBlocksCaraousal from './BuildingBlocksCaraousal';

function BuildingBlocks() {
    return (
        <div className='flex flex-col justify-center items-center  align-center w-full mt-[100px] sm:mt-60'>
            <h1 className='font-black text-2xl sm:text-4xl text-black text-center'>BUILDING BLOCKS OF AQUANURCH SYSTEM</h1>
            <p className='font-semibold text-lg mt-[36px] text-[#00208C] my-[30px]'>HOW WE BUILT</p>
            <div className='hidden sm:flex relative p-4 w-[800px] mt-[10px] mb-[500px] sm:ml-[90px] '>
                <img src={BuildOne} className="h-64 w-64 hover:scale-125 transform transition duration-500 absolute right-0 top-0" alt="ImageA" />
                <img src={BuildTwo} className="h-64 w-64 hover:scale-125 transform transition duration-500 absolute top-[75px] left-[320px]" alt="ImageB" />
                <img src={BuildThree} className="h-64 w-64 hover:scale-125 transform transition duration-500 absolute top-[257px] left-[455px]" alt="ImageC" />
                <img src={BuildFour} className="h-64 w-64 hover:scale-125 transform transition duration-500 absolute top-[130px] left-[76px]" alt="ImageD" />
                <img src={BuildFive} className="h-64 w-64 hover:scale-125 transform transition duration-500 absolute top-[340px] left-[213px]" alt="ImageE" />
                <img src={BuildSix} className="h-64 w-64 hover:scale-125 transform transition duration-500 absolute top-[330px] left-[-50px]" alt="ImageF" />
                <img src={BuildSeven} className="h-64 w-64 hover:scale-125 transform transition duration-500 absolute top-[130px] left-[-170px]" alt="ImageF" />
            </div>
            <BuildingBlocksCaraousal />
        </div>
    )
}

export default BuildingBlocks