import React from 'react'
import { useState, useEffect, useRef } from "react";
import HeadingImage from "../../static/HeadingImage.svg"
import blobAnimation from "./blobAnimation.json";
import Lottie from "lottie-react";
import ImageA from "../../static/ImageA.svg";
import ImageB from "../../static/ImageB.svg";
import ImageC from "../../static/ImageC.svg";
import ImageD from "../../static/ImageD.svg";
import ImageE from "../../static/ImageE.svg";
import ImageF from "../../static/ImageF.svg";



function HeadingSection() {
  return (
    <div className='w-full mt-20 flex flex-col items-center'>
      <div className='hidden sm:flex flex-col w-full mt-14 text-center '>
        <p className=' font-bold text-4xl text-black'>Nature Intelligence x Artificial Intelligence</p>
        <p className=' font-normal text-lg text-[#757575] mt-6'>To establish climate resilient new nature economy</p>
      </div>
      <div className='flex sm:hidden flex-col w-full mt-14 text-center'>
        <p className=' font-bold text-3xl text-black'>Nature Intelligence <br></br>x <br></br>Artificial Intelligence</p>
      </div>
      <div className='hidden sm:flex relative p-4 w-[800px] mt-[10px] mb-[500px]'>
        <img src={ImageA} className="h-64 w-64 hover:scale-125 transform transition duration-500 absolute right-0 top-0" alt="ImageA"/>
        <img src={ImageB} className="h-64 w-64 hover:scale-125 transform transition duration-500 absolute top-[75px] left-[320px]" alt="ImageB"/>
        <img src={ImageC} className="h-64 w-64 hover:scale-125 transform transition duration-500 absolute top-[257px] left-[455px]" alt="ImageC"/>
        <img src={ImageD} className="h-64 w-64 hover:scale-125 transform transition duration-500 absolute top-[154px] left-[76px]" alt="ImageD"/>
        <img src={ImageE} className="h-64 w-64 hover:scale-125 transform transition duration-500 absolute top-[327px] left-[213px]" alt="ImageE"/>
        <img src={ImageF} className="h-64 w-64 hover:scale-125 transform transition duration-500 absolute top-[336px] left-[-95px]" alt="ImageF"/>
      </div>
      <div className='flex sm:hidden justify-center mt-11'>
           <img src={HeadingImage} alt="headingImg"/>
      </div>
      <div className='flex sm:hidden flex-col w-full mt-[30px] text-center'>
        <p className=' font-normal text-lg text-[#757575] mt-6'>To establish climate resilient new nature economy</p>
      </div>
      <div className='sm:hidden w-[160px] mx-auto mt-8 flex aligne-center justify-center border border-slate-600 px-5 py-3 rounded-3xl bg-[#00208C] cursor-pointer'>
        <p className='font-medium text-sm text-white' onClick={() => window.open('https://www.aquanurch.com/overallsummary')}>
          Get a Demo {' >>'}
        </p>
      </div>
    </div>
  )
}

export default HeadingSection