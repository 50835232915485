import React from 'react'
import WorkCultureSvg from "../../../static/WorkCultureSvg.svg"
import MobileWorkCulture from "../../../static/MobileWorkCulture.svg"

function WorkCulture() {
  return (
    <div className='flex flex-col items-center space-y-[60px] my-[20px] sm:my-[60px] mt-20'>
         <p className='text-bold text-4xl text-black'>Work Culture</p>
         <img src={WorkCultureSvg} alt="workCultureSvg" className='hidden sm:block'/>
         <img src={MobileWorkCulture} alt="workCultureSvg" className='sm:hidden'/>
    </div>
  )
}

export default WorkCulture