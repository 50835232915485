import React from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import TempImg from "../../../static/TempImg.svg"
import BuildOne from "../../../static/BuildOne.svg";
import BuildTwo from "../../../static/BuildTwo.svg";
import BuildThree from "../../../static/BuildThree.svg";
import BuildFour from "../../../static/BuildFour.svg";
import BuildFive from "../../../static/BuildFive.svg";
import BuildSix from "../../../static/BuildSix.svg";
import BuildSeven from "../../../static/BuildSeven.svg";
import { isMobile } from "react-device-detect";

function BuildingBlocksCaraousal() {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        arrows : isMobile ? true : false,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
                dots: true,
                arrows : isMobile ? true : false,
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2,
                arrows : isMobile ? true : false,
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                arrows : isMobile ? true : false,
              }
            }
          ]
    };
    return (
        <div className='w-full sm:hidden'>
            <Slider className='sm:pb-8 mr-5' {...settings}>
                <div className='px-3'>
                    <img src={BuildOne} alt="tempImg" />
                </div>
                <div className='px-3'>
                    <img src={BuildTwo} alt="tempImg" />
                </div>
                <div className='px-3'>
                    <img src={BuildThree} alt="tempImg" />
                </div>
                <div className='px-3'>
                    <img src={BuildFour} alt="tempImg" />
                </div>
                <div className='px-3'>
                    <img src={BuildFive} alt="tempImg" />
                </div>
                <div className='px-3 mt-6'>
                    <img src={BuildSix} alt="tempImg" />
                </div>
                <div className='px-3'>
                    <img src={BuildSeven} alt="tempImg" />
                </div>
            </Slider>
        </div>
    )
}

export default BuildingBlocksCaraousal