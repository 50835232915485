import React from 'react';
import { useState } from 'react';
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';

const SubscribeNewsletter = () => {
	const navigate = useNavigate();

	const [email, setEmail] = useState('');
	const sendMail = async () => {
		if (!email) {
			return toast.error('Please complete all fields before proceeding', {
				position: 'top-center',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: 'light',
			});
		}
		const templateParams = {
			email,
			body: "I'd like to subscribe to the newsletter",
		};
		await emailjs
			.send(
				'service_hvnje3h',
				'template_zbxasgp',
				templateParams,
				'tGyeYe6CvxT-65_Wn'
			)
			.then(
				(response) => {
					console.log('SUCCESS!', response.status, response.text);
					setEmail('');
					toast.success('Thnaks for Subscribing newsletter ', {
						position: 'top-center',
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: 'light',
					});
				},
				(err) => {
					console.log('FAILED...', err);
				}
			);
	};
	return (
		<div className='px-4 py-8 bg-gradient-to-r from-purple-50 to-red-50 mt-24 sm:mt-40 flex flex-col sm:mx-28 items-center justify-center mb-10'>
			<h2 className=' text-[32px] font-semibold text-center mb-2'>
				Subscribe To NatureDots Newsletter
			</h2>
			<p className='text-center text-[#3D465A] mb-6 mt-5 tex-tbase font-normal'>
				Uncovering Indian Fish Farmer Psychology And
				<br />
				Market Analysis Through Research.
			</p>
			<div className='flex max-w-[380px] mt-5'>
				<button
					className='bg-blue-900 text-white px-4 py-2 rounded-md hover:bg-blue-800 transition-colors'
					onClick={() =>
						navigate(`/product`, {
							state: { params1: 'subscribed', params2: email },
						})
					}
				>
					Subscribe
				</button>
			</div>
			<ToastContainer />
		</div>
	);
};

export default SubscribeNewsletter;
