import React from 'react';
import Logo from '../../static/NaturedotsLogo.svg';
import { AiOutlineMenu } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import useAnalyticsEventTracker from '../GAEventFiles/useAnalyticsEventTracker.js';

function Navbar({ isOpen, toggleDropdown }) {
	const navigate = useNavigate();
	const gaEventTracker = useAnalyticsEventTracker();
	const redirectToProductPage = () => {
		navigate('/product');
		gaEventTracker('Product Page');
		// window.location.reload()
	};
	const redirectToHomePage = () => {
		navigate('/');
		gaEventTracker('Main Page');
		// window.location.reload()
	};
	return (
		<div className='flex justify-between h-16 sm:h-24 px-5 sm:px-28 py-3 items-center shadow-md fixed top-0 z-[999] w-full bg-white'>
			<img
				className=' cursor-pointer h-[50px]'
				src={Logo}
				alt='logoImg'
				onClick={() => navigate('/')}
			/>
			<div className='hidden sm:flex align-center'>
				<div className=' space-x-8 font-medium text-sm flex items-center align-center ml-48'>
					<p
						className={`cursor-pointer ${
							window.location.pathname === '/' ? 'text-[#009900]' : 'text-black'
						}`}
						onClick={redirectToHomePage}
					>
						Our Dream
					</p>
					<p
						className={`cursor-pointer ${
							window.location.pathname === '/product'
								? 'text-[#009900]'
								: 'text-black'
						}`}
						onClick={redirectToProductPage}
					>
						Product
					</p>
					{/* <p className=' cursor-pointer' onClick={() => window.open('https://www.aquanurch.com/overallsummary')}>
                              About us
                         </p> */}
					<p
						className={`cursor-pointer ${
							window.location.pathname === '/insights'
								? 'text-[#009900]'
								: 'text-black'
						}`}
						onClick={() => navigate('/insights')}
					>
						Insights
					</p>
					<p
						className={`cursor-pointer ${
							window.location.pathname === '/careers'
								? 'text-[#009900]'
								: 'text-black'
						}`}
						onClick={() => navigate('/careers')}
					>
						Careers
					</p>
				</div>
				<div
					className=' flex aligne-center justify-center border border-slate-600 px-5 py-3 bg-white rounded-3xl ml-8 cursor-pointer'
					onClick={() =>
						window.open('https://www.aquanurch.com/overallsummary')
					}
				>
					<p className='font-medium text-sm'>Login / Signup</p>
				</div>
				<div
					className=' flex aligne-center justify-center border border-slate-600 px-5 py-3 rounded-3xl ml-8 bg-[#00208C] cursor-pointer'
					onClick={() =>
						window.open('https://www.aquanurch.com/overallsummary')
					}
				>
					<p className='font-medium text-sm text-white'>Get a Demo {' >>'}</p>
				</div>
			</div>
			{!isOpen && (
				<div className='sm:hidden'>
					<AiOutlineMenu onClick={toggleDropdown} />
				</div>
			)}
		</div>
	);
}

export default Navbar;
