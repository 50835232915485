import { React, useState } from 'react'
import "../ImpactSection/OurImpact.css"
import LinkedIn from "../../static/LinkedIn.svg"
import Twitter from "../../static/Twitter.svg"
import Snehal from "../../static/Snehal.png"
import Aatish from "../../static/Aatish.png"
import Shiva from "../../static/Shiva.png"
import Ayush from "../../static/Ayush.png"
import Pragati from "../../static/Pragati.png"
import Vishwas from "../../static/Vishwas.png"
import Lokesh from "../../static/Lokesh.png"
import Akash from "../../static/Aakash.png"
import Vinay from "../../static/Vinay.png"
import Marta from "../../static/Marta.png"
import Shiv from "../../static/Shiv.png"
import Arun from "../../static/Arun.png"
import Basant from "../../static/Basant.png"

function TeamSection() {
  const [show, setShow] = useState(false)
  const [visible, setVisible] = useState(false);
  return (
    <div className='flex flex-col justify-center items-center align-center w-full mt-[100px] sm:mt-60'>
      <p className=' text-4xl font-bold '>SUPER TEAM</p>
      <div className='flex flex-col space-y-5 my-8 sm:mx-[81px]'>

        <p className=' px-4 sm:px-0 text-lg font-normal leading-9  text-center text-[#757575]'>
          Our Super-team is growing! We are all high-calibre, Purpose-driven individuals who are combining our forces together to create transformational solutions for solving our nature's and social problems. We are reinventing, redefining the businesses for a sustainable future.
        </p>
        {visible && <p className=' px-4 sm:px-0 text-lg font-normal leading-9  text-center text-[#757575]'>Our team members are experienced learners representing different geographies, with diverse educational backgrounds ranging from treasured grass-roots education to premier Indian Institutes and other world-ranking universities, weaving their own stories of change and impact.</p>}
        {visible && <p className=' px-4 sm:px-0 text-lg font-normal leading-9  text-center text-[#757575]'>We bring a collective experience of 50+ years, 110+ end-to-end projects in 30 countries across 4 continents for global grassroots organizations to mega-corporates, for people to governments, to deliver transformational change to achieve nature-positive growth.</p>}

        {!visible && <p className=' text-center cursor-pointer' onClick={() => setVisible(true)}> Read More... </p>}
      </div>
      <div className="hidden sm:grid grid-cols-4 gap-24 mt-16">
        <div className=' flex flex-col space-y-2 align-center items-center'>
          <div className=' bg-contain '> <img className='rounded-md' src={Snehal} alt="snehal" /> </div>
          <p className=' text-base font-semibold'> SNEHAL VERMA </p>
          <p className=' text-sm font-medium text-[#757575]'> Co-founder & Director </p>
          <div className='flex align-center items-center space-x-4'>
            <img src={LinkedIn} alt="linkedIn" onClick={() => window.open('https://www.linkedin.com/in/snehal-verma/')} />
            <img src={Twitter} alt="twitter" onClick={() => 'https://twitter.com/thesnehalverma'} />
          </div>
        </div>
        <div className=' flex flex-col space-y-2 align-center items-center'>
          <div className=''> <img className='rounded-md' src={Aatish} alt="Aatish" /> </div>
          <p className=' text-base font-semibold'> MOHAMMAD AATISH KHAN </p>
          <p className=' text-sm font-medium text-[#757575]'> Co-founder & Director </p>
          <div className='flex align-center items-center space-x-4'>
            <img src={LinkedIn} alt="linkedIn" onClick={() => window.open('https://www.linkedin.com/in/aatishkhan/')} />
            <img src={Twitter} alt="twitter" onClick={() => window.open('https://twitter.com/aatish_khan?lang=en')} />
          </div>
        </div>
        <div className=' flex flex-col space-y-2 align-center items-center'>
          <div className=''> <img className='rounded-md' src={Ayush} alt="Ayush" /> </div>
          <p className=' text-base font-semibold'> AYUSH PRASAD </p>
          <p className=' text-sm font-medium text-[#757575]'> Data Engineer </p>
          <div className='flex align-center items-center space-x-4'>
            <img src={LinkedIn} alt="linkedIn" onClick={() => window.open('https://www.linkedin.com/in/ayushprd/')} />
            <img src={Twitter} alt="twitter" />
          </div>
        </div>
        <div className=' flex flex-col space-y-2 align-center items-center'>
          <div className=''>  <img className='rounded-md' src={Shiva} alt="Shiva" /> </div>
          <p className=' text-base font-semibold'> SHIVA KUMAR</p>
          <p className=' text-sm font-medium text-[#757575]'> Product Designer </p>
          <div className='flex align-center items-center space-x-4'>
            <img src={LinkedIn} alt="linkedIn" onClick={() => window.open('https://www.linkedin.com/in/shiva-kumar-91243616a/')} />
            <img src={Twitter} alt="twitter" />
          </div>
        </div>
        <div className=' flex flex-col space-y-2 align-center items-center'>
          <div className=''> <img className='rounded-md' src={Vinay} alt="Vinay" /> </div>
          <p className=' text-base font-semibold'>VINAY SOLANKI </p>
          <p className=' text-sm font-medium text-[#757575]'> Frontend Developer </p>
          <div className='flex align-center items-center space-x-4'>
            <img src={LinkedIn} alt="linkedIn" onClick={() => window.open('https://www.linkedin.com/in/vinay-solanki-023186173/')} />
            <img src={Twitter} alt="twitter" />
          </div>
        </div>
        <div className=' flex flex-col space-y-2 align-center items-center'>
          <div className=''> <img className='rounded-md' src={Lokesh} alt="Lokesh" /> </div>
          <p className=' text-base font-semibold'> LOKESH BATHINA </p>
          <p className=' text-sm font-medium text-[#757575]'> Geo-informatics Developer  </p>
          <div className='flex align-center items-center space-x-4'>
            <img src={LinkedIn} alt="linkedIn" />
            <img src={Twitter} alt="twitter" />
          </div>
        </div>
        <div className=' flex flex-col space-y-2 align-center items-center'>
          <div className=''>  <img className='rounded-md' src={Pragati} alt="Pragati" />  </div>
          <p className=' text-base font-semibold'> PRAGATI SHETTY</p>
          <p className=' text-sm font-medium text-[#757575]'> Research Associate - Aquaculture  </p>
          <div className='flex align-center items-center space-x-4'>
            <img src={LinkedIn} alt="linkedIn" onClick={() => window.open('https://www.linkedin.com/in/pragati-shetty-1a08ba19a/')} />
            <img src={Twitter} alt="twitter" />
          </div>
        </div>
        <div className=' flex flex-col space-y-2 align-center items-center'>
          <div className=''>  <img className='rounded-md' src={Vishwas} alt="Vishwas" /></div>
          <p className=' text-base font-semibold'> VISHWAS DEVENANI </p>
          <p className=' text-sm font-medium text-[#757575]'> Data Scientist - Image Processing  </p>
          <div className='flex align-center items-center space-x-4'>
            <img src={LinkedIn} alt="linkedIn" onClick={() => window.open('https://www.linkedin.com/in/vishwas-devnani-882740175/')} />
            <img src={Twitter} alt="twitter" />
          </div>
        </div>
        <div className=' flex flex-col space-y-2 align-center items-center'>
          <div className=''> <img className='rounded-md' src={Marta} alt="Marta" /> </div>
          <p className=' text-base font-semibold'> MARTA LO PRESTI </p>
          <p className=' text-sm font-medium text-[#757575] text-center'> Lead Economics & <br></br> New Business Development</p>
          <div className='flex align-center items-center space-x-4'>
            <img src={LinkedIn} alt="linkedIn" />
            <img src={Twitter} alt="twitter" />
          </div>
        </div>
        <div className=' flex flex-col space-y-2 align-center items-center'>
          <div className=''> <img src={Shiv} className="rounded-md" alt="Shiv" /> </div>
          <p className=' text-base font-semibold'> SHIV VERMA </p>
          <p className=' text-sm font-medium text-[#757575]'> Para Legal Counsel</p>
          <div className='flex align-center items-center space-x-4'>
            <img src={LinkedIn} alt="linkedIn" onClick={() => window.open('https://www.linkedin.com/in/shiv-verma-596633166/')} />
            <img src={Twitter} alt="twitter" />
          </div>
        </div>
        <div className=' flex flex-col space-y-2 align-center items-center'>
          <div className=''> <img src={Arun} className="rounded-md" alt="Arun" /> </div>
          <p className=' text-base font-semibold'> ARUN THOMAS </p>
          <p className=' text-sm font-medium text-[#757575]'> Product Manager</p>
          <div className='flex align-center items-center space-x-4'>
            <img src={LinkedIn} alt="linkedIn" />
            <img src={Twitter} alt="twitter" />
          </div>
        </div>
        <div className=' flex flex-col space-y-2 align-center items-center'>
          <div className=''>  <img className='rounded-md' src={Basant} alt="Basant" />  </div>
          <p className=' text-base font-semibold'> BASANT SINGH </p>
          <p className=' text-sm font-medium text-[#757575]'> Fisheries Development Officer  </p>
          <div className='flex align-center items-center space-x-4'>
            <img src={LinkedIn} alt="linkedIn" />
            <img src={Twitter} alt="twitter" />
          </div>
        </div>


      </div>
      <div className='flex flex-col'>
        <div className='sm:hidden grid grid-cols-2 gap-10 mx-5 mt-5'>
          <div className=' flex flex-col space-y-2 align-center items-center'>
            <div className=' bg-contain '> <img className='rounded-md' src={Snehal} alt="snehal" /> </div>
            <p className=' text-base font-semibold'> SNEHAL VERMA </p>
            <p className=' text-sm font-medium text-[#757575] text-center'> Co-founder & Director </p>
            <div className='flex align-center items-center space-x-4'>
              <img src={LinkedIn} alt="linkedIn" onClick={() => window.open('https://www.linkedin.com/in/snehal-verma/')} />
              <img src={Twitter} alt="twitter" onClick={() => 'https://twitter.com/thesnehalverma'} />
            </div>
          </div>
          <div className=' flex flex-col space-y-2 align-center items-center'>
            <div className=''> <img className='rounded-md' src={Aatish} alt="Aatish" /> </div>
            <p className=' text-base font-semibold text-center'> MOHAMMAD AATISH KHAN </p>
            <p className=' text-sm font-medium text-[#757575] . text-center'> Co-founder & Director </p>
            <div className='flex align-center items-center space-x-4'>
              <img src={LinkedIn} alt="linkedIn" onClick={() => window.open('https://www.linkedin.com/in/aatishkhan/')} />
              <img src={Twitter} alt="twitter" onClick={() => window.open('https://twitter.com/aatish_khan?lang=en')} />
            </div>
          </div>
          {show &&
            <>
              <div className=' flex flex-col space-y-2 align-center items-center'>
                <div className=''> <img className='rounded-md' src={Ayush} alt="Ayush" /> </div>
                <p className=' text-base font-semibold'> AYUSH PRASAD </p>
                <p className=' text-sm font-medium text-[#757575]'> Data Engineer </p>
                <div className='flex align-center items-center space-x-4'>
                  <img src={LinkedIn} alt="linkedIn" onClick={() => window.open('https://www.linkedin.com/in/ayushprd/')} />
                  <img src={Twitter} alt="twitter" />
                </div>
              </div>
              <div className=' flex flex-col space-y-2 align-center items-center'>
                <div className=''>  <img className='rounded-md' src={Shiva} alt="Shiva" /> </div>
                <p className=' text-base font-semibold'> SHIVA KUMAR</p>
                <p className=' text-sm font-medium text-[#757575]'> Product Designer </p>
                <div className='flex align-center items-center space-x-4'>
                  <img src={LinkedIn} alt="linkedIn" onClick={() => window.open('https://www.linkedin.com/in/shiva-kumar-91243616a/')} />
                  <img src={Twitter} alt="twitter" />
                </div>
              </div>
              <div className=' flex flex-col space-y-2 align-center items-center'>
                <div className=''> <img className='rounded-md' src={Vinay} alt="Vinay" /> </div>
                <p className=' text-base font-semibold'>VINAY SOLANKI </p>
                <p className=' text-sm font-medium text-[#757575]'> Frontend Developer </p>
                <div className='flex align-center items-center space-x-4'>
                  <img src={LinkedIn} alt="linkedIn" onClick={() => window.open('https://www.linkedin.com/in/vinay-solanki-023186173/')} />
                  <img src={Twitter} alt="twitter" />
                </div>
              </div>
              <div className=' flex flex-col space-y-2 align-center items-center'>
                <div className=''> <img className='rounded-md' src={Lokesh} alt="Lokesh" /> </div>
                <p className=' text-base font-semibold'> LOKESH BATHINA </p>
                <p className=' text-sm font-medium text-[#757575] text-center'> Geo-informatics Developer  </p>
                <div className='flex align-center items-center space-x-4'>
                  <img src={LinkedIn} alt="linkedIn" />
                  <img src={Twitter} alt="twitter" />
                </div>
              </div>
              <div className=' flex flex-col space-y-2 align-center items-center'>
                <div className=''>  <img className='rounded-md' src={Pragati} alt="Pragati" />  </div>
                <p className=' text-base font-semibold'> PRAGATI SHETTY</p>
                <p className=' text-sm font-medium text-[#757575] text-center'> Research Associate - Aquaculture  </p>
                <div className='flex align-center items-center space-x-4'>
                  <img src={LinkedIn} alt="linkedIn" onClick={() => window.open('https://www.linkedin.com/in/pragati-shetty-1a08ba19a/')} />
                  <img src={Twitter} alt="twitter" />
                </div>
              </div>
              <div className=' flex flex-col space-y-2 align-center items-center'>
                <div className=''>  <img className='rounded-md' src={Vishwas} alt="Vishwas" /></div>
                <p className=' text-base font-semibold text-center'> VISHWAS DEVENANI </p>
                <p className=' text-sm font-medium text-[#757575] text-center'> Data Scientist - Image Processing  </p>
                <div className='flex align-center items-center space-x-4'>
                  <img src={LinkedIn} alt="linkedIn" onClick={() => window.open('https://www.linkedin.com/in/vishwas-devnani-882740175/')} />
                  <img src={Twitter} alt="twitter" />
                </div>
              </div>
              <div className=' flex flex-col space-y-2 align-center items-center'>
                <div className=''> <img className='rounded-md' src={Marta} alt="Marta" /> </div>
                <p className=' text-base font-semibold'> MARTA LO PRESTI </p>
                <p className=' text-sm font-medium text-[#757575] text-center'> Lead Economics & <br></br> New Business Development</p>
                <div className='flex align-center items-center space-x-4'>
                  <img src={LinkedIn} alt="linkedIn" />
                  <img src={Twitter} alt="twitter" />
                </div>
              </div>
              <div className=' flex flex-col space-y-2 align-center items-center'>
                <div className=''> <img src={Shiv} className="rounded-md" alt="Shiv" /> </div>
                <p className=' text-base font-semibold'> SHIV VERMA </p>
                <p className=' text-sm font-medium text-[#757575] text-center'> Para Legal Counsel</p>
                <div className='flex align-center items-center space-x-4'>
                  <img src={LinkedIn} alt="linkedIn" onClick={() => window.open('https://www.linkedin.com/in/shiv-verma-596633166/')} />
                  <img src={Twitter} alt="twitter" />
                </div>
              </div>
              <div className=' flex flex-col space-y-2 align-center items-center'>
                <div className=''> <img src={Arun} className="rounded-md" alt="Arun" /> </div>
                <p className=' text-base font-semibold'> ARUN THOMAS </p>
                <p className=' text-sm font-medium text-[#757575]'> Product Manager</p>
                <div className='flex align-center items-center space-x-4'>
                  <img src={LinkedIn} alt="linkedIn" />
                  <img src={Twitter} alt="twitter" />
                </div>
              </div>
              <div className=' flex flex-col space-y-2 align-center items-center'>
                <div className=''>  <img className='rounded-md' src={Basant} alt="Basant" />  </div>
                <p className=' text-base font-semibold'> BASANT SINGH </p>
                <p className=' text-sm font-medium text-[#757575] text-center'> Fisheries Development Officer  </p>
                <div className='flex align-center items-center space-x-4'>
                  <img src={LinkedIn} alt="linkedIn" />
                  <img src={Twitter} alt="twitter" />
                </div>
              </div>
            </>
          }
        </div>
        {!show && <div className='sm:hidden px-5 py-2 border bg-white rounded-xl border-[#1F1F1F] mx-auto mt-5' onClick={() => setShow(true)}>
          <p className="text-[#1F1F1F]">View All</p>
        </div>}
      </div>
    </div>
  )
}

export default TeamSection