import React from 'react';
import coverImage from '../../components/BlogsDetailsPage/Assets/CoverImage.jpg';

const FeatureInsightsCard = () => {
	return (
		<div className='w-full mt-24 sm:mt-40 flex flex-col sm:px-28'>
			<div className='flex flex-col items-center justify-center space-y-4'>
				<p className='text-black text-4xl font-bold'>Featured Insights</p>
				<p className='text-[#757575] text-lg font-normal text-center'>
					Our latest thinking on the issues that matter most in business and
					management.
				</p>
			</div>
			<div className='mt-14 flex flex-col sm:flex-row items-start sm:space-x-[32px] px-4 sm:px-0'>
				<div className='w-full sm:w-[50%] h-full'>
					<img
						src={coverImage}
						alt='Fish Ponds and Polluted Water'
						className='w-full h-[400px] rounded-lg shadow-md object-cover'
					/>
				</div>
				<div className='flex flex-col space-y-3 sm:w-[50%] h-full'>
					<p className='text-sm font-light text-black'>Newsletter</p>
					<p className='text-[40px] font-bold text-black'>
						The Rising Tide: Challenges of Fish Diseases in Global Aquaculture
					</p>
					<p className='text-[#757575] text-base font-normal'>
						Aquaculture, or fish farming, is a crucial part of our food system,
						providing a significant source of animal protein, food security and
						livelihoods worldwide. According to the{' '}
						<a
							href='https://openknowledge.fao.org/server/api/core/bitstreams/7493258e-e420-4840-a95d-cfec8833219d/content'
							className='text-blue-500 cursor-pointer underline underline-blue-500'
							onClick={(e) => e.stopPropagation()}
							target='_blank'
							rel='noopener noreferrer'
						>
							Food and Agricultural Organization (FAO, 2024)
						</a>
						, global fish production reached 185 million tonnes in 2022, a
						noticeable 4.4% increase from 2020. In total, fisheries and
						aquaculture produced a record 223.2 million tonnes worth USD 472
						billion, providing about 15% of global animal protein intake, and in
						some Asian and African countries, this figure is over 50%.{' '}
						<span className='text-blue-500 font-bold'>Read More...</span>
					</p>
				</div>
			</div>
		</div>
	);
};

export default FeatureInsightsCard;
